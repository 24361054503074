import { Card } from "@mui/material";
import SoftDataTable from "components/UI/SoftDataTable";
import { useUiStateStore } from "store/ui-state";
import { columns } from "./data/isoDataTable";

import staticData from "assets/iso-static.json";

const ISOTable = () => {
  const { selectedDocTab } = useUiStateStore();

  return (
    <Card sx={{ py: 5 }}>
      <SoftDataTable
        table={{ columns, rows: staticData[selectedDocTab?.name] || [] }}
      />
    </Card>
  );
};

export default ISOTable;
