import * as React from "react";

import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { rankItem } from "@tanstack/match-sorter-utils";
import SoftBox from "components/UI/SoftBox";
import DataTableHeadCell from "./DataTableHeadCell";
import DataTableBodyCell from "./DataTableBodyCell";
import SoftTypography from "components/UI/SoftTypography";
import DebouncedFilter from "./DebouncedFilter";
import SoftButton from "../SoftButton";
import AutoCompleteOption from "../AutoCompleteOption";
import { ArrowBackIos, ArrowForwardIos, Download } from "@mui/icons-material";

const fuzzyFilter = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({
    itemRank,
  });
  return itemRank.passed;
};

export default function SoftDataTable({
  table: { rows, columns },
  FilterComponent,
  withSearchFilter = true,
  withExport,
  exportFn,
  exportButtonProps,
  ...rest
}) {
  const [sorting, setSorting] = React.useState([]);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const matchesMd = useMediaQuery("(max-width:600px)");

  const table = useReactTable({
    data: rows,
    columns,
    state: {
      sorting,
      globalFilter,
    },
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => table.setPageSize(rest?.pageStart || 5), []);

  return (
    <SoftBox
      sx={{
        "& .MuiTableRow-root:not(:last-child)": {
          "& td": {
            borderBottom: ({ borders: { borderWidth, borderColor } }) =>
              `${borderWidth[1]} solid ${borderColor}`,
          },
        },
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
        px={!rest.noPaddingFilter && 3}
        sx={{ overflowX: "auto" }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <SoftTypography fontSize={14}>Show entries:</SoftTypography>
          <AutoCompleteOption
            noLabel
            options={["5", "10", "20", "30", "40", "50"]}
            value={["", `${table.getState().pagination.pageSize}`]}
            onSelect={(e, newVal) =>
              newVal && table.setPageSize(Number(newVal))
            }
            width="30%"
          />
          {!matchesMd && (
            <SoftTypography fontSize={13}>
              Showing {`${table.getRowModel().rows.length}`} of {rows.length}{" "}
              entries
            </SoftTypography>
          )}
        </Stack>
        <Stack
          direction="row"
          sx={{
            justifyContent: "flex-end",
            alignItems: "center",
            my: matchesMd ? 2 : 1,
          }}
          spacing={1}
        >
          {withSearchFilter && (
            <DebouncedFilter
              value={globalFilter}
              onChange={(value) => setGlobalFilter(String(value))}
              placeholder="Search all columns..."
            />
          )}
          {withExport &&
            (!matchesMd ? (
              <SoftButton
                variant="outlined"
                onClick={exportFn}
                color="error"
                size="small"
                sx={{ p: 1, fontSize: 11 }}
                {...exportButtonProps}
              >
                Export Excel
              </SoftButton>
            ) : (
              <Tooltip title="Export Excel">
                <IconButton onClick={exportFn}>
                  <Download />
                </IconButton>
              </Tooltip>
            ))}
        </Stack>
      </Stack>
      <TableContainer
        sx={{
          overflowY: "auto",
          maxHeight: 600,
        }}
      >
        <Table id="myTable">
          <SoftBox
            component="thead"
            sx={{
              position: "sticky",
              top: 0,
              backgroundColor: "#fff",
              zIndex: 50,
            }}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <DataTableHeadCell
                    width="auto"
                    key={header.id}
                    sorted={header.column.getIsSorted()}
                    isSorted={header.column.getIsSorted()}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </DataTableHeadCell>
                ))}
              </TableRow>
            ))}
          </SoftBox>
          <TableBody>
            {rest.isLoading || table.getRowModel().rows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <SoftTypography sx={{ fontSize: 13, textAlign: "center" }}>
                    {rest.isLoading ? "Loading Data ..." : "No Data Available"}
                  </SoftTypography>
                </TableCell>
              </TableRow>
            ) : (
              table.getRowModel().rows.map((row) => {
                return (
                  <TableRow key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <DataTableBodyCell
                          key={cell.id}
                          width={cell.column.columnDef?.width}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </DataTableBodyCell>
                      );
                    })}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        direction="row"
        sx={{
          py: 2,
          px: 3,
          alignItems: "center",
          justifyContent: "flex-end",
          flex: 1,
          position: "sticky",
          bottom: 0,
          backgroundColor: "#fff",
        }}
        spacing={1}
      >
        <SoftButton
          sx={{ px: 1.5 }}
          variant="outlined"
          size="small"
          color="error"
          disabled={!table.getCanPreviousPage()}
          onClick={() => table.previousPage()}
          startIcon={<ArrowBackIos />}
        >
          Prev
        </SoftButton>
        <SoftButton
          sx={{ px: 1.5 }}
          variant="outlined"
          size="small"
          color="error"
          disabled={!table.getCanNextPage()}
          onClick={() => table.nextPage()}
          endIcon={<ArrowForwardIos />}
        >
          Next
        </SoftButton>
        <SoftTypography fontSize={14}>
          Page:{" "}
          <strong>
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </strong>
        </SoftTypography>
      </Stack>
    </SoftBox>
  );
}
