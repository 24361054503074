import { CircularProgress, Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import AutoCompleteOption from "components/UI/AutoCompleteOption";
import SoftButton from "components/UI/SoftButton";
import SoftDatePicker from "components/UI/SoftDatePicker";
import { getLogbookReport, getUsers } from "new-services";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useUiStateStore } from "store/ui-state";
import { formatDate, objectToQueryParam } from "utils";

const mappingDataOptions = (allUserData) => {
  const result = [];

  for (const val of allUserData) {
    const workplaceIndex = result.findIndex(
      (wp) => wp.fid === val.workplace.fid && wp.name === val.workplace.name
    );

    if (workplaceIndex !== -1) {
      const teamIndex = result[workplaceIndex].teams.findIndex(
        (team) => team.fid === val.team.fid && team.name === val.team.name
      );
      if (teamIndex !== -1) {
        result[workplaceIndex].teams[teamIndex].users.push({
          name: val.name,
          fid: val.fid,
          email: val.email,
        });
        continue;
      }

      result[workplaceIndex].teams.push({
        name: val.team.name,
        fid: val.team.fid,
        users: [{ name: val.name, fid: val.fid, email: val.email }],
      });

      continue;
    }
    if (val.workplace.fid && val.workplace.name) {
      result.push({
        fid: val.workplace.fid,
        name: val.workplace.name,
        teams: [
          {
            name: val.team.name,
            fid: val.team.fid,
            users: [{ name: val.name, fid: val.fid, email: val.email }],
          },
        ],
      });
    }
  }

  return result;
};

const FilterData = ({ onSetFilter }) => {
  const { setAllUserData, setGlobalLoading, setExportData, globalLoading } =
    useUiStateStore();
  const [allDataOptions, setAllDataOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [prevData, setPrevData] = useState({
    workplace: "",
    data: [],
  });

  const [filter, setFilter] = useState({
    startDate: "",
    endDate: "",
    team: { name: "" },
    workplace: { name: "" },
    user: { name: "" },
    noTeam: true,
  });

  const handleChangeDate = (newVal, key) =>
    setFilter({ ...filter, [key]: newVal });

  const handleChangeOption = (e, newVal, key) => {
    if (!newVal?.name)
      return setFilter({
        ...filter,
        [key]: { name: "" },
      });

    const { name, fid, email } = newVal;
    if (key === "workplace") {
      setSelectedOption(newVal);
      return setFilter({
        ...filter,
        [key]: { name, fid },
        team: { name: "" },
        user: { name: "" },
      });
    }
    if (key === "team") {
      return setFilter({ ...filter, [key]: { name, fid }, user: { name: "" } });
    }
    setFilter({ ...filter, [key]: { name, fid, email } });
  };

  const getSelecteUserIds = () => {
    const ids = [];
    !filter.team.fid
      ? selectedOption?.teams?.forEach((t) =>
          t?.users.forEach((u) => ids.push(u.fid))
        )
      : selectedOption?.teams
          ?.filter((t) => t.fid === filter.team.fid)
          .forEach((t) => t?.users.forEach((u) => ids.push(u.fid)));
    return ids;
  };

  const handleFilter = async () => {
    if (!filter?.workplace?.name)
      return toast.error("Please select a workplace");
    if (!filter?.startDate || !filter?.endDate)
      return toast.error("Please select start and end date");
    onSetFilter(filter);
    if (
      prevData?.filter?.workplace?.name === filter.workplace.name &&
      prevData?.filter?.startDate === filter.startDate &&
      prevData?.filter?.endDate === filter.endDate
    ) {
      return setExportData(
        prevData.data.filter((c) =>
          filter?.user?.fid
            ? c.user_id === filter.user.fid
            : getSelecteUserIds().includes(c.user_id)
        )
      );
    }
    setGlobalLoading(true);

    const res = await getLogbookReport(
      objectToQueryParam({
        start: filter.startDate ? formatDate(filter.startDate) : "",
        end: filter.endDate ? formatDate(filter.endDate) : "",
        user_id: filter.user?.fid || "",
      })
    );

    if (res.status_code === 200) {
      const filteredData = res.data.filter((c) =>
        getSelecteUserIds().includes(c.user_id)
      );
      setExportData(filteredData);

      if (res.data.length > 0 && !filter.user.fid)
        setPrevData({ filter, data: res.data });
    }
    setGlobalLoading(false);
  };

  const { isLoading } = useQuery(["users"], getUsers, {
    onSuccess: (res) => {
      if (res.status_code === 200) {
        setAllUserData(res.data);
        setAllDataOptions(mappingDataOptions(res.data));
      }
    },
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3} lg={2}>
        <AutoCompleteOption
          noFetcher
          options={allDataOptions}
          placeholder="Select Workplace"
          getOptionLabel={(option) => option.name}
          noLabel
          onSelect={handleChangeOption}
          value={["workplace", filter.workplace]}
        />
      </Grid>
      <Grid item xs={12} md={3} lg={2}>
        <AutoCompleteOption
          noFetcher
          placeholder="Select Team"
          getOptionLabel={(option) => option.name}
          options={selectedOption?.teams}
          value={["team", filter.team]}
          onSelect={handleChangeOption}
          noLabel
        />
      </Grid>
      <Grid item xs={12} md={3} lg={2}>
        <AutoCompleteOption
          noFetcher
          options={
            selectedOption?.teams?.find((tm) => tm.fid === filter.team.fid)
              ?.users
          }
          getOptionLabel={(option) => option.name}
          placeholder="Select User"
          onSelect={handleChangeOption}
          noLabel
          value={["user", filter.user]}
        />
      </Grid>
      <Grid item xs={12} md={3} lg={2}>
        <SoftDatePicker
          noLabel
          onChange={handleChangeDate}
          disableDate={(valDate) => valDate > new Date()}
          placeholder="Start Date"
          value={["startDate", filter.startDate]}
        />
      </Grid>
      <Grid item xs={12} md={3} lg={2}>
        <SoftDatePicker
          noLabel
          onChange={handleChangeDate}
          disableDate={(valDate) => valDate < filter.startDate}
          placeholder="End Date"
          value={["endDate", filter.endDate]}
        />
      </Grid>
      <Grid item xs={12} md={3} lg={2}>
        <SoftButton
          onClick={handleFilter}
          disabled={isLoading || globalLoading}
          mt={-1}
          variant="gradient"
          endIcon={
            isLoading || globalLoading ? (
              <CircularProgress color="inherit" size={18} />
            ) : null
          }
          color="error"
        >
          Apply Filter
        </SoftButton>
      </Grid>
    </Grid>
  );
};

export default FilterData;
