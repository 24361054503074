import { Divider } from "@mui/material";
import OvertimeTable from "components/Tables/OvertimeTable";
import SoftTypography from "components/UI/SoftTypography";

const Overtime = () => {
  return (
    <>
      <SoftTypography my={1.5} fontWeight="bold">
        Overtime Report
      </SoftTypography>
      <Divider />
      <OvertimeTable />
    </>
  );
};

export default Overtime;
