// react-router-dom components
import { useNavigate, useParams } from "react-router-dom";

// Soft UI Dashboard React components
import SoftBox from "components/UI/SoftBox";
import SoftTypography from "components/UI/SoftTypography";
import { CircularProgress, Stack } from "@mui/material";
import { useEffect } from "react";
import Cookies from "js-cookie";

import { convertBase64 } from "utils";
import { useUiStateStore } from "store/ui-state";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";

function RedirectPage() {
  const navigate = useNavigate();
  const { setUserLoggedIn } = useUiStateStore();
  const { access_token, refresh_token } = useParams();

  useEffect(() => {
    try {
      Cookies.set("access_token", convertBase64("encode", access_token));
      Cookies.set("refresh_token", convertBase64("encode", refresh_token));
      sessionStorage.setItem(
        "authUser",
        JSON.stringify(jwtDecode(access_token))
      );
      setUserLoggedIn({ authUser: jwtDecode(access_token) });
      navigate("/profile");
    } catch (error) {
      toast.error("Invalid redirect params");
      setTimeout(() => {
        navigate("/authentication/sign-in");
      }, 3000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SoftBox
      sx={{
        display: "flex",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <CircularProgress color="error" size={18} />
        <SoftTypography fontSize={16}>
          Redirecting in progress...
        </SoftTypography>
      </Stack>
    </SoftBox>
  );
}

export default RedirectPage;
