import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);

// const firebaseConfigMDM = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY_MDM,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_MDM,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_MDM,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_MDM,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_MDM,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID_MDM,
// };

// const appMDM = initializeApp(firebaseConfigMDM);

export const authMDM = getAuth(app);
export const dbMDM = getFirestore(app);

export const auth = getAuth(app);
export const db = getFirestore(app);
