import SoftTypography from "components/UI/SoftTypography";

export const columns = [
  {
    accessorKey: "date",
    cell: (info) => (
      <SoftTypography fontSize={13}>{info.getValue() || "-"}</SoftTypography>
    ),
    header: "Date",
  },

  {
    accessorKey: "project",
    cell: (info) => (
      <SoftTypography fontSize={13}>
        {info.getValue() || info.row.original.value || "-"}
      </SoftTypography>
    ),
    header: "Project",
    width: "150px",
  },
  {
    accessorKey: "usecase",
    cell: (info) =>
      info?.getValue() ? (
        info
          .getValue()
          .split("\n")
          .map((uc, idx) => (
            <SoftTypography fontSize={13} key={idx}>
              {uc || "-"}
            </SoftTypography>
          ))
      ) : (
        <SoftTypography fontSize={13}>-</SoftTypography>
      ),

    header: "Usecase",
    width: "150px",
  },
  {
    accessorKey: "activity",
    cell: (info) =>
      info?.getValue() ? (
        info
          .getValue()
          .split("\n")
          .map((ac, idx) => (
            <SoftTypography fontSize={13} key={idx}>
              {ac || "-"}
            </SoftTypography>
          ))
      ) : (
        <SoftTypography fontSize={13}>-</SoftTypography>
      ),

    width: "250px",
    header: "Activity",
  },
  {
    accessorKey: "clock_in",
    cell: (info) => (
      <SoftTypography
        fontSize={13}
        color={info.row.original.timeDiff < 9 * 3600 ? "error" : "inherit"}
        fontWeight={info.row.original.timeDiff < 9 * 3600 ? "bold" : false}
      >
        {info?.getValue() || "-"}
      </SoftTypography>
    ),
    header: "Clock In",
    width: "70px",
  },
  {
    accessorKey: "clock_out",
    cell: (info) => (
      <SoftTypography
        fontSize={13}
        color={info.row.original.timeDiff < 9 * 3600 ? "error" : "inherit"}
        fontWeight={info.row.original.timeDiff < 9 * 3600 ? "bold" : false}
      >
        {info?.getValue() || "-"}
      </SoftTypography>
    ),
    header: "Clock Out",
    width: "70px",
  },
  {
    accessorKey: "hoursPerDay",
    cell: (info) => (
      <SoftTypography
        fontSize={13}
        color={info.row.original.timeDiff < 9 * 3600 ? "error" : "inherit"}
        fontWeight={info.row.original.timeDiff < 9 * 3600 ? "bold" : false}
      >
        {info?.getValue() || "-"}
      </SoftTypography>
    ),
    header: "Work Hours",
    width: "70px",
  },

  {
    accessorKey: "work_mode",
    cell: (info) => {
      return (
        <SoftTypography fontSize={13}>
          {info.getValue()
            ? info.getValue()
            : info.row.original.value
            ? "Libur"
            : ""}
        </SoftTypography>
      );
    },
    header: "Work Mode",
  },
];
