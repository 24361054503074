import React, { useMemo } from "react";
import styles from "./timesheet.module.css";
import { Tab } from "@headlessui/react";

const UserTab = ({ data }) => {
  const isCompleted = useMemo(() => {
    return data?.data.filter((d) => d?.value === "").length === 0;
  }, [data]);

  return (
    <Tab
      className={({ selected }) =>
        `${styles["tab-button"]} ${selected && styles["active"]} ${
          isCompleted && styles["completed"]
        }`
      }
    >
      {data?.user_name || "No Name"}
    </Tab>
  );
};

export default UserTab;
