import { Divider } from "@mui/material";
import HolidayTable from "components/Tables/HolidayTable";
import SoftTypography from "components/UI/SoftTypography";

const Holidays = () => {
  return (
    <>
      <SoftTypography my={1.5} fontWeight="bold">
        Holidays Management
      </SoftTypography>
      <Divider />
      <HolidayTable />
    </>
  );
};

export default Holidays;
