import {
  Card,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  useMediaQuery,
} from "@mui/material";
import SoftDataTable from "components/UI/SoftDataTable";
import SoftBox from "components/UI/SoftBox";
import SoftTypography from "components/UI/SoftTypography";
import { useState } from "react";
import { useUiStateStore } from "store/ui-state";
import { columns } from "./data/overtimeDataTable";
import SoftDatePicker from "components/UI/SoftDatePicker";
import SoftButton from "components/UI/SoftButton";
import SoftBottomModal from "components/UI/SoftBottomModal";
import {
  convertToHoursMinutes,
  exportExcel,
  formatDate,
  objectToQueryParam,
} from "utils";
import { Sort } from "@mui/icons-material";
import { getOvertimeReport } from "new-services";
import { useQuery } from "@tanstack/react-query";
import useReportPreview from "hooks/use-report-preview";

const today = new Date().toISOString().split("T")[0];

const FilterOvertime = ({
  filter,
  onSetDate,
  matchesMd,
  onOpenFilter,
  onFilter,
}) => {
  const { setOvertimeData, setGlobalLoading, globalLoading } =
    useUiStateStore();

  const handleFilter = async () => {
    setGlobalLoading(true);

    const res = await getOvertimeReport(
      objectToQueryParam({
        start: filter?.startDate ? formatDate(filter.startDate) : "",
        end: filter?.endDate ? formatDate(filter.endDate) : "",
        nocache: "true",
      })
    );

    if (res.status_code === 200) {
      setOvertimeData(Object.values(res.data));
      if (matchesMd) onOpenFilter(false);
    }

    setGlobalLoading(false);
    onSetDate(filter);
  };

  const handleChangeDate = (newVal, key) => {
    if (key === "startDate")
      return onFilter({ ...filter, endDate: "", [key]: newVal });
    onFilter({ ...filter, [key]: newVal });
  };

  return (
    <Stack
      direction={matchesMd ? "column" : "row"}
      spacing={1.5}
      alignItems={matchesMd ? "" : "center"}
    >
      <SoftDatePicker
        noLabel={true}
        value={["startDate", filter.startDate]}
        placeholder="Start Date"
        onChange={handleChangeDate}
        disableDate={(valDate) => valDate > new Date()}
        width={matchesMd ? undefined : 150}
        size="small"
      />
      <SoftDatePicker
        noLabel={true}
        value={["endDate", filter.endDate]}
        placeholder="End Date"
        onChange={handleChangeDate}
        disableDate={(valDate) =>
          valDate < new Date(filter.startDate) || valDate > new Date()
        }
        width={matchesMd ? undefined : 150}
        size="small"
      />
      <SoftBox textAlign="right">
        <SoftButton
          size="small"
          color="error"
          variant="gradient"
          onClick={handleFilter}
          disabled={globalLoading || !filter.startDate || !filter.endDate}
          endIcon={
            globalLoading ? (
              <CircularProgress color="inherit" size={14} />
            ) : null
          }
        >
          Filter
        </SoftButton>
      </SoftBox>
    </Stack>
  );
};

const OvertimeTable = () => {
  const { onSetDate } = useReportPreview();
  const { overtimeData, setOvertimeData } = useUiStateStore();
  const matchesMd = useMediaQuery("(max-width:600px)");
  const [openFilter, setOpenFilter] = useState(false);

  const [filter, setFilter] = useState({
    startDate: today,
    endDate: today,
  });

  const { isLoading } = useQuery(
    ["overtime-report"],
    () =>
      getOvertimeReport(
        objectToQueryParam({ start: today, end: today, nocache: "true" })
      ),
    {
      onSuccess: (res) => {
        if (res.status_code === 200) {
          setOvertimeData(Object.values(res.data));
        }
      },
      refetchOnWindowFocus: false,
      refetchInterval: 1200000,
    }
  );

  return (
    <Card>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
        pt={4}
      >
        <SoftTypography variant="h5">Overtime Data</SoftTypography>
        {matchesMd ? (
          <>
            <IconButton onClick={() => setOpenFilter(true)}>
              <Sort />
            </IconButton>
            <SoftBottomModal
              open={openFilter}
              title="Filter Presences Data"
              handleClose={() => setOpenFilter(false)}
              height={100}
            >
              <FilterOvertime
                filter={filter}
                onFilter={setFilter}
                onSetDate={onSetDate}
                onOpenFilter={setOpenFilter}
                matchesMd
              />
            </SoftBottomModal>
          </>
        ) : (
          <FilterOvertime
            filter={filter}
            onFilter={setFilter}
            onSetDate={onSetDate}
            onOpenFilter={setOpenFilter}
            matchesMd={matchesMd}
          />
        )}
      </SoftBox>
      <Divider />
      <SoftDataTable
        table={{ columns, rows: overtimeData || [] }}
        isLoading={isLoading}
        withExport
        exportButtonProps={{ disabled: !filter.startDate && !filter.endDate }}
        exportFn={() =>
          exportExcel(
            overtimeData.map((overtime) => {
              const absentInSeconds =
                (overtime.workdays_count -
                  Object.keys(overtime.data).length +
                  overtime.sick_leave) *
                2 *
                3600;

              const apporvedRulesInSeconds =
                overtime.requested_overtime_total_seconds - absentInSeconds;

              const absentDays =
                overtime.workdays_count - Object.keys(overtime.data).length;
              const presentDays = overtime.workdays_count - absentDays;

              return {
                name: overtime?.user?.name || "-",
                role: overtime?.user?.role || "-",
                project: overtime?.user?.project || "-",
                project_manager: overtime?.user?.project_manager || "-",
                work_days: overtime?.workdays_count,
                present_days: presentDays,
                absent_days: absentDays,
                annual_leave: overtime?.annual_leave,
                sick_leave: overtime?.sick_leave,
                overtime_days: overtime?.overtime_count,
                overtime_requested: convertToHoursMinutes(
                  overtime?.valid_overtime_total_seconds
                ),
                overtime_approved: convertToHoursMinutes(
                  overtime?.requested_overtime_total_seconds
                ),
                overtime_by_rules: convertToHoursMinutes(
                  apporvedRulesInSeconds
                ),
              };
            }),
            "overtime-data"
          )
        }
      />
    </Card>
  );
};

export default OvertimeTable;
