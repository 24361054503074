import { Chip, CircularProgress, Stack, useMediaQuery } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import InputWithLabel from "components/Inputs/InputWithLabel";
import SoftBottomModal from "components/UI/SoftBottomModal";
import SoftBox from "components/UI/SoftBox";
import SoftButton from "components/UI/SoftButton";
import SoftDateTimePicker from "components/UI/SoftDateTimePicker";
import SoftModal from "components/UI/SoftModal";
import SoftTypography from "components/UI/SoftTypography";
import { updateRequest } from "new-services";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useUiStateStore } from "store/ui-state";
import {
  convertTimeFromDate,
  convertToHoursMinutes,
  formatKey,
  isLtEightHours,
  responseHandler,
} from "utils";

const calculateWorkHours = (presenceData, presenceRequest) => {
  let clockIn =
    presenceRequest?.request_data?.from?.clock_in || presenceData?.clock_in;
  let clockOut =
    presenceRequest?.request_data?.from?.clock_out || presenceData?.clock_out;
  let workHours;
  // Priority 1: Presence Data
  if (clockIn && clockOut) {
    clockIn = new Date(clockIn);
    clockOut = new Date(clockOut);
    workHours = Math.min((clockOut - clockIn) / (1000 * 60 * 60), 9);
  } else {
    clockIn = null;
    clockOut = null;
    workHours = 0;
  }

  return { clockIn, clockOut, workHours };
};

const calculateValidOvertimeStart = (clockOut, startDate) => {
  const minimumOvertimeStart = new Date(clockOut);
  minimumOvertimeStart.setMinutes(minimumOvertimeStart.getMinutes() + 60);

  return {
    isValid: new Date(startDate) >= minimumOvertimeStart,
    suggestedStart: minimumOvertimeStart,
  };
};

const calculateRequestOvertime = (startDate, endDate) => {
  return (new Date(endDate) - new Date(startDate)) / 1000;
};

const calculateValidOvertimeHours = (startDate, endDate, clockOut) => {
  const { isValid, suggestedStart } = calculateValidOvertimeStart(
    clockOut,
    startDate
  );
  const actualOvertimeStart = isValid ? new Date(startDate) : suggestedStart;
  const actualOvertimeEnd = new Date(endDate);

  let validOvertimeHours =
    (actualOvertimeEnd - actualOvertimeStart) / (1000 * 3600);

  // Convert valid overtime hours to seconds
  const validOvertimeSeconds = validOvertimeHours * 3600;

  return {
    validOvertimeHours,
    validOvertimeSeconds,
    isValidStart: isValid,
    suggestedStart,
  };
};

const RequestCardModal = ({
  cardData,
  type,
  chipStyle: { text, styles },
  open,
  handleClose,
}) => {
  const {
    setGlobalLoading,
    userLoggedIn,
    globalLoading,
    filteredAllRequest,
    allRequest,
    setAllRequest,
    setFilteredAllRequest,
  } = useUiStateStore();

  const [overtimeData, setOvertimeData] = useState(cardData?.request_data);
  const [rejectedReason, setRejectedReason] = useState("");
  const matchesmd = useMediaQuery("(max-width:600px)");
  const ModalWrapper = matchesmd ? SoftBottomModal : SoftModal;

  const presenceData = cardData?.request_data?.presence_data;
  const presenceRequest = cardData?.request_data?.presence_request;

  const { clockIn, clockOut, workHours } = calculateWorkHours(
    presenceData,
    presenceRequest
  );

  const startDate = overtimeData?.start;
  const endDate = overtimeData?.end;

  const { validOvertimeHours, isValidStart, suggestedStart } =
    calculateValidOvertimeHours(startDate, endDate, clockOut, workHours);

  const isValidTime = new Date(endDate) > new Date(startDate);

  const handleUpdateResult = useCallback(
    (updateStatus, rejectedCustom) => {
      const reqIndex = filteredAllRequest.findIndex(
        (req) => req.fid === cardData.fid
      );
      const reqIndex2 = allRequest.findIndex((req) => req.fid === cardData.fid);
      const values = [...filteredAllRequest];
      const values2 = [...allRequest];
      values2[reqIndex2] = {
        ...values2[reqIndex2],
        status: updateStatus,
        checker_name: userLoggedIn.userInfo.name,
        rejected_reason: rejectedCustom || rejectedReason,
      };
      values[reqIndex] = {
        ...values[reqIndex],
        status: updateStatus,
        checker_name: userLoggedIn.userInfo.name,
        rejected_reason: rejectedCustom || rejectedReason,
      };
      setFilteredAllRequest(values);
      setAllRequest(values2);
    },
    [
      allRequest,
      cardData.fid,
      filteredAllRequest,
      rejectedReason,
      setAllRequest,
      setFilteredAllRequest,
      userLoggedIn.userInfo.name,
    ]
  );

  const { mutate: updateUserRequest, isLoading: loadUpdateReq } = useMutation(
    updateRequest,
    {
      onSuccess: (res) => {
        if (res.status_code === 200) {
          setRejectedReason("");
          handleClose();
        }
        responseHandler({ res });
      },
    }
  );

  const handleApprove = async () => {
    const now = new Date();
    const updateStatus = [
      ...cardData.status,
      { title: "approved", date_time: now },
    ];

    const { validOvertimeSeconds } = calculateValidOvertimeHours(
      startDate,
      endDate,
      clockOut,
      workHours
    );

    const requestedOvertimeSeconds = calculateRequestOvertime(
      cardData?.request_data?.start,
      cardData?.request_data?.end
    );
    handleUpdateResult(updateStatus);
    updateUserRequest({
      id: cardData.fid,
      payload: {
        request_data: {
          ...overtimeData,
          valid_overtime_seconds: validOvertimeSeconds,
          requested_overtime_seconds: requestedOvertimeSeconds,
          start_request_date_time: cardData?.request_data?.start,
          end_request_date_time: cardData?.request_data?.end,
        },
        checker_name: userLoggedIn.userInfo.name,
        checked_by: userLoggedIn.userInfo.fid,
        status: updateStatus,
      },
    });
  };

  const handleReject = () => {
    if (!rejectedReason) return toast.error("Please enter a reason");
    const updateStatus = [
      ...cardData.status,
      { title: "rejected", date_time: new Date() },
    ];
    handleUpdateResult(updateStatus);
    updateUserRequest({
      id: cardData.fid,
      payload: {
        checker_name: userLoggedIn.userInfo.name,
        checked_by: userLoggedIn.userInfo.fid,
        rejected_reason: rejectedReason,
        status: updateStatus,
      },
    });
  };

  const handleChangeDateTime = useCallback((newVal, key) => {
    setOvertimeData((prev) => ({
      ...prev,
      [key]: newVal,
    }));
  }, []);

  const disableStartDate = useCallback(
    (date) => {
      // If startDate is not set, don't disable any dates
      if (!startDate) return false;

      const dateToCheck = new Date(date);
      const startDateObj = new Date(startDate);

      // Compare only the date part (year, month, day) ignoring time
      return (
        dateToCheck.getFullYear() !== startDateObj.getFullYear() ||
        dateToCheck.getMonth() !== startDateObj.getMonth() ||
        dateToCheck.getDate() !== startDateObj.getDate()
      );
    },
    [startDate]
  );

  const disableEndDate = useCallback(
    (date) => {
      // If startDate is not set, don't disable any dates
      if (!startDate) return false;

      const dateToCheck = new Date(date);
      const startDateObj = new Date(startDate);

      // Reset time components to compare dates only
      dateToCheck.setHours(0, 0, 0, 0);
      startDateObj.setHours(0, 0, 0, 0);

      // Create a date object for startDate + 1 day
      const dayAfterStartDate = new Date(startDateObj);
      dayAfterStartDate.setDate(startDateObj.getDate() + 1);

      // Allow only startDate or startDate + 1 day
      // Return true to disable all other dates
      return !(
        dateToCheck.getTime() === startDateObj.getTime() ||
        dateToCheck.getTime() === dayAfterStartDate.getTime()
      );
    },
    [startDate]
  );

  useEffect(() => {
    setGlobalLoading(loadUpdateReq);
  }, [loadUpdateReq, setGlobalLoading]);

  return (
    <ModalWrapper title="Detail Request" open={open} handleClose={handleClose}>
      {type !== "user" && (
        <Stack mt={-1} spacing={1} direction="row">
          <SoftTypography fontSize={14} fontWeight="bold">
            Status :
          </SoftTypography>
          <Chip
            size="small"
            label={text}
            color={styles.color}
            sx={{ color: "#fff" }}
          />
        </Stack>
      )}
      <Stack spacing={0.5}>
        <SoftTypography fontSize={14} fontWeight="bold">
          Type :{" "}
          <SoftTypography variant="span" fontWeight="medium">
            {formatKey(cardData.title)} {formatKey(cardData.type)}
          </SoftTypography>
        </SoftTypography>
        <SoftTypography fontSize={14} fontWeight="bold">
          Requester :{" "}
          <SoftTypography variant="span" fontWeight="medium">
            {cardData.requester_name}
          </SoftTypography>
        </SoftTypography>
        <SoftTypography fontSize={14} fontWeight="bold">
          Reason :{" "}
          <SoftTypography variant="span" fontWeight="medium">
            {cardData.request_data.reason}
          </SoftTypography>
        </SoftTypography>
        <SoftTypography fontSize={14} fontWeight="bold">
          Checked By :{" "}
          <SoftTypography variant="span" fontWeight="medium">
            {cardData?.checker_name}
          </SoftTypography>
        </SoftTypography>
        {cardData?.rejected_reason && (
          <SoftTypography fontSize={14} fontWeight="bold">
            Rejected Reason :{" "}
            <SoftTypography variant="span" fontWeight="medium">
              {cardData?.rejected_reason}
            </SoftTypography>
          </SoftTypography>
        )}

        <Stack spacing={0.5}>
          {presenceData && (
            <SoftBox>
              <SoftTypography fontSize={14} fontWeight="bold">
                Presence Data
              </SoftTypography>
              <Stack spacing={0.5}>
                <Stack direction="row">
                  <SoftTypography fontSize={13} width="30%">
                    Work Mode
                  </SoftTypography>
                  <SoftTypography fontSize={13}>
                    {presenceData?.work_mode}
                  </SoftTypography>
                </Stack>
                <Stack direction="row">
                  <SoftTypography fontSize={13} width="30%">
                    Clock In
                  </SoftTypography>
                  <SoftTypography fontSize={13}>
                    {convertTimeFromDate.toLocaleDateString(clockIn)}{" "}
                    {convertTimeFromDate.toLocaleTimeString(clockIn)}
                  </SoftTypography>
                </Stack>
                <Stack direction="row">
                  <SoftTypography fontSize={13} width="30%">
                    Clock Out
                  </SoftTypography>
                  <SoftTypography fontSize={13}>
                    {clockOut &&
                      convertTimeFromDate.toLocaleDateString(clockOut)}{" "}
                    {clockOut &&
                      convertTimeFromDate.toLocaleTimeString(clockOut)}
                  </SoftTypography>
                </Stack>
                {clockIn && clockOut && (
                  <SoftTypography
                    sx={{
                      fontSize: 13,
                    }}
                    color={
                      isLtEightHours(clockIn / 1000, clockOut / 1000)
                        ? "error"
                        : undefined
                    }
                  >
                    {" "}
                    Work hours estimation :{" "}
                    {convertToHoursMinutes(
                      (new Date(clockOut).getTime() -
                        new Date(clockIn).getTime()) /
                        1000
                    )}
                  </SoftTypography>
                )}
              </Stack>
            </SoftBox>
          )}

          <SoftBox>
            <SoftTypography fontSize={14} fontWeight="bold">
              Overtime Date:{" "}
              <SoftTypography variant="span" fontWeight="medium">
                {convertTimeFromDate.toLocaleDateString(startDate)}
              </SoftTypography>
            </SoftTypography>
            <Stack spacing={1} direction="row">
              <SoftDateTimePicker
                value={["start", startDate]}
                disableDate={disableStartDate}
                onChange={handleChangeDateTime}
                disabled={type !== "administrator"}
              />
              <SoftDateTimePicker
                value={["end", endDate]}
                disableDate={disableEndDate}
                onChange={handleChangeDateTime}
                disabled={type !== "administrator"}
              />
            </Stack>
          </SoftBox>
        </Stack>
      </Stack>

      {type === "administrator" && (
        <>
          <Stack mt={1}>
            {!presenceData && !presenceRequest && (
              <SoftTypography color="error" fontSize={13}>
                *No presence data available, work hours will be: -2
              </SoftTypography>
            )}
            {!isValidStart && (
              <SoftTypography color="error" fontSize={13}>
                *Suggested overtime start: {suggestedStart.toLocaleTimeString()}
                {` (1 hours after clock out)`}
              </SoftTypography>
            )}

            <SoftTypography
              sx={{
                fontSize: 13,
              }}
            >
              {" "}
              Overtime valid hours estimation :{" "}
              {convertToHoursMinutes(validOvertimeHours * 60 * 60)}
            </SoftTypography>
          </Stack>
          <InputWithLabel
            sx={{ mt: 2 }}
            noLabel
            value={["", rejectedReason]}
            onChange={(e) => setRejectedReason(e.target.value)}
            placeholder="Rejected reason"
          />
          <Stack mt={1.5} direction="row" justifyContent="flex-end">
            <Stack direction="row" alignItems="center" spacing={1}>
              <SoftButton
                onClick={handleReject}
                variant="outlined"
                color="error"
                disabled={!rejectedReason || !isValidTime}
                endIcon={
                  globalLoading ? (
                    <CircularProgress color="inherit" size={18} />
                  ) : null
                }
              >
                Reject
              </SoftButton>
              <SoftButton
                onClick={handleApprove}
                variant="gradient"
                color="error"
                endIcon={
                  globalLoading ? (
                    <CircularProgress color="inherit" size={18} />
                  ) : null
                }
                disabled={rejectedReason || !isValidTime || !isValidStart}
              >
                Approve
              </SoftButton>
            </Stack>
          </Stack>
        </>
      )}
    </ModalWrapper>
  );
};

export default RequestCardModal;
