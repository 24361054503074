import { Divider } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import RequestCardPresenceModal from "components/Modals/RequestCardPresenceModal";
import SelectOption from "components/UI/SelectOption";
import SoftBox from "components/UI/SoftBox";
import SoftGridView from "components/UI/SoftGridView";
import SoftTypography from "components/UI/SoftTypography";
import { getUserRequests } from "new-services";
import React, { useState, useTransition } from "react";
import { useUiStateStore } from "store/ui-state";
import { getStyleAndText, objectToQueryParam } from "utils";

export const filterOptions = {
  keys: [
    "requester_name",
    "type",
    { name: "title", weight: 2 },
    { name: "status.title", weight: 2 },
  ],
};

const Presence = () => {
  const {
    allRequest,
    filteredAllRequest,
    setFilteredAllRequest,
    setAllRequest,
  } = useUiStateStore();

  const { isLoading } = useQuery(
    ["allUserRequest", "presence"],
    () => getUserRequests(objectToQueryParam({ title: "presence" })),
    {
      onSuccess: (res) => {
        if (res.status_code === 200) {
          setAllRequest(res.data);
          setFilteredAllRequest(res.data);
        }
      },
      refetchInterval: 120000,
      refetchOnWindowFocus: false,
    }
  );

  const [isPending, startTransition] = useTransition();
  const [filterStatus, setFilterStatus] = useState("All");

  const [selectedRequest, setSelectedRequest] = useState({});

  const handleCustomFilter = (e) => {
    setFilterStatus(e.target.value);
    if (e.target.value === "All") {
      return setFilteredAllRequest(allRequest);
    }

    setFilteredAllRequest(
      allRequest.filter((request) => {
        const foundData = request.status.find(
          (stat) => stat.title === e.target.value.toLowerCase()
        );

        if (e.target.value === "On Process" && request.status.length > 1) {
          return false;
        }
        return foundData;
      })
    );
  };

  const [open, setOpen] = useState(false);

  return (
    <>
      {selectedRequest?.fid && (
        <RequestCardPresenceModal
          cardData={selectedRequest}
          type="administrator"
          chipStyle={getStyleAndText(
            selectedRequest?.status[selectedRequest?.status?.length - 1]?.title
          )}
          open={open}
          handleClose={() => setOpen(false)}
        />
      )}
      <SoftTypography
        variant="h5"
        sx={(theme) => ({
          mb: 1,
          [theme.breakpoints.down("md")]: {
            fontSize: 22,
          },
        })}
        mt={2}
      >
        Request Presence
      </SoftTypography>
      <Divider />
      <SoftGridView
        filteredData={filteredAllRequest}
        filterProps={{
          defaultData: allRequest,
          options: filterOptions,
          setterFn: setFilteredAllRequest,
          startTransition: startTransition,
          onFilterChange: () => {
            if (filterStatus !== "All") setFilterStatus("All");
          },
        }}
        customFilter={
          <SoftBox
            sx={(theme) => {
              return {
                width: "15%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                [theme.breakpoints.down("md")]: {
                  width: "100%",
                },
              };
            }}
          >
            <SelectOption
              options={["All", "Approved", "Rejected", "On Process"].map(
                (val) => ({ key: val, value: val })
              )}
              value={["", filterStatus]}
              onSelect={handleCustomFilter}
            />
          </SoftBox>
        }
        loading={isLoading || isPending}
        handleOpen={(request) => {
          setOpen(true);
          setSelectedRequest(request);
        }}
      />
    </>
  );
};

export default Presence;
