import { getUserInfo } from "new-services";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useUiStateStore } from "store/ui-state";

export default function useAuthListener() {
  const { userLoggedIn, resetAllStateAndSignOut, setUserLoggedIn } =
    useUiStateStore();
  const navigate = useNavigate();
  const authUser = JSON.parse(sessionStorage.getItem("authUser"));

  useEffect(() => {
    (async () => {
      if (
        (userLoggedIn?.authUser || authUser?.user_id) &&
        !userLoggedIn?.userInfo
      ) {
        const res = await getUserInfo(authUser?.user_id);
        if (res.status_code === 200) setUserLoggedIn({ userInfo: res.data });
        if (res.status_code === 401)
          resetAllStateAndSignOut(() => navigate("/authentication/sign-in"));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUserLoggedIn, userLoggedIn?.authUser]);

  return { userLoggedIn };
}
