import { Card, Divider } from "@mui/material";
import SoftDataTable from "components/UI/SoftDataTable";
import SoftBox from "components/UI/SoftBox";
import SoftTypography from "components/UI/SoftTypography";
import { columns } from "./data/timesheetDataTable";

const TimeSheetTable = ({ rows, tableTitle, tableProps }) => {
  return (
    <Card>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={3}
        pt={4}
      >
        <SoftTypography variant="h6">{tableTitle}</SoftTypography>
      </SoftBox>
      <Divider />
      <SoftDataTable table={{ columns, rows: rows || [] }} {...tableProps} />
    </Card>
  );
};

export default TimeSheetTable;
