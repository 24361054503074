import { MUTATE_METHODS, NO_AUTH_ENDPOINTS } from "constants";
import Cookies from "js-cookie";
import { convertBase64, responseHandler } from "utils";

const callApi = async ({
  method,
  endpoint,
  payload,
  customURL,
  customAuth,
}) => {
  const headers = new Headers();
  let body;

  if (customAuth) headers.append(customAuth.key, customAuth.value);

  if (!NO_AUTH_ENDPOINTS.includes(endpoint) && !customAuth)
    headers.append(
      "Authorization",
      `Bearer ${convertBase64("decode", Cookies.get("access_token"))}`
    );

  if (MUTATE_METHODS.includes(method)) {
    headers.append("Content-Type", "application/json");
    body = JSON.stringify(payload);
  }

  try {
    const response = await fetch(
      `${customURL || process.env.REACT_APP_BASEURL + endpoint}`,
      {
        headers,
        method,
        body,
      }
    );
    const result = await response.json();
    if (method === "GET") responseHandler({ res: result, type: method });
    return result;
  } catch (err) {
    console.log("error api call", err);
    return { status_code: 500 };
  }
};

export default callApi;
