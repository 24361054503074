import { useState } from "react";
import { weekday } from "constants";
import { formatDate } from "utils";

const defaultDate = `${weekday[new Date().getDay()]}, ${formatDate(
  new Date(),
  "local"
)}`;

const useReportPreview = () => {
  const [showPreview, setShowPreview] = useState(false);
  const [disablePreview, setDisablePreview] = useState(true);
  const [date, setDate] = useState(defaultDate);

  const onSetDate = (filter) => {
    filter.startDate && filter.endDate
      ? setDisablePreview(false)
      : setDisablePreview(true);
    new Date(filter.startDate).toLocaleDateString() !==
    new Date(filter.endDate).toLocaleDateString()
      ? setDate(filter)
      : setDate(
          `${weekday[new Date(filter.startDate).getDay()]}, ${formatDate(
            filter.endDate,
            "local"
          )}`
        );
  };

  return { showPreview, date, disablePreview, onSetDate, setShowPreview };
};

export default useReportPreview;
