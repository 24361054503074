import { Search } from "@mui/icons-material";
import { InputAdornment, Stack, TextField, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { fuzzySearch } from "utils";

const FuzzyFilter = ({
  data,
  defaultData,
  setterFn,
  options,
  startTransition,
  onFilterChange,
}) => {
  const [enteredFilter, setEnteredFilter] = useState("");

  const matchesmd = useMediaQuery("(max-width:600px)");

  const handleFilter = (e) => {
    setEnteredFilter(e.target.value);
    startTransition(() =>
      fuzzySearch(data, defaultData, options, setterFn, e.target.value)
    );
    onFilterChange && onFilterChange();
  };

  return (
    <Stack direction="row" justifyContent="flex-end">
      <TextField
        sx={{ width: matchesmd ? "75%" : "auto" }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search fontSize="small" />
            </InputAdornment>
          ),
        }}
        placeholder="Search Request "
        value={enteredFilter}
        onChange={handleFilter}
      />
    </Stack>
  );
};

export default FuzzyFilter;
