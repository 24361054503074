import RequestHeader from "components/Headers/RequestHeader";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import React, { useMemo } from "react";
import Presence from "./pages/Presence";
import { useUiStateStore } from "store/ui-state";
import TabPanel from "components/UI/TabPanel";
import withAdminAuth from "layouts/hoc-admin-page";
import Profile from "./pages/Profile";
import Overtime from "./pages/Overtime";
import Settings from "components/Icons/Settings";
import Document from "components/Icons/Document";
import Office from "components/Icons/Office";

// Define allowed company roles
const ALLOWED_COMPANY_ROLES = ["Project Manager"];

const RequestPage = () => {
  const { userLoggedIn, currentRequestTab } = useUiStateStore();
  const isAdmin =
    userLoggedIn?.userInfo?.system_role?.toLowerCase() === "administrator";

  console.log(userLoggedIn);

  // Dynamically build request pages based on user role
  const requestPages = useMemo(() => {
    const pages = {
      presence: {
        title: "Presence",
        component: <Presence />,
        icon: <Document />,
      },
      overtime: {
        title: "Overtime",
        component: <Overtime />,
        icon: <Settings />,
      },
      profile: { title: "Profile", component: <Profile />, icon: <Office /> },
    };

    return isAdmin
      ? [pages.presence, pages.overtime, pages.profile]
      : [pages.overtime];
  }, [isAdmin]);

  console.log(isAdmin, requestPages);

  // Ensure currentRequestTab is valid
  const safeCurrentTab = Math.min(currentRequestTab, requestPages?.length - 1);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <RequestHeader requestPages={requestPages} />
      {requestPages.map(({ component }, index) => (
        <TabPanel index={index} value={safeCurrentTab} key={index}>
          {component}
        </TabPanel>
      ))}
    </DashboardLayout>
  );
};

export default withAdminAuth(RequestPage, ALLOWED_COMPANY_ROLES);
