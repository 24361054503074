import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";

import ReportsHeader from "components/Headers/ReportsHeader";
import TabPanel from "components/UI/TabPanel";
import TimeSheet from "./pages/TimeSheet";
import { useUiStateStore } from "store/ui-state";
import Holidays from "./pages/Holidays";
import withAdminAuth from "layouts/hoc-admin-page";
import Overtime from "./pages/Overtime";

const reportPages = [
  { component: <TimeSheet /> },
  { component: <Overtime /> },
  { component: <Holidays /> },
];

const Reports = () => {
  const { currentReportTab } = useUiStateStore();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ReportsHeader />
      {reportPages.map(({ component }, index) => (
        <TabPanel value={currentReportTab} index={index} key={index}>
          {component}
        </TabPanel>
      ))}
    </DashboardLayout>
  );
};

export default withAdminAuth(Reports);
