/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import SoftBox from "components/UI/SoftBox";
import Document from "components/Icons/Document";
import { useUiStateStore } from "store/ui-state";
import useHeader from "hooks/use-header";
import SoftTypography from "components/UI/SoftTypography";

const mapValue = { 0: "ISO9001", 1: "ISO27001" };

function DocumentsHeader() {
  const { tabsOrientation } = useHeader();
  const { selectedDocTab, setSelectedDocTab } = useUiStateStore();

  const handleSetTabValue = (_, newValue) => {
    setSelectedDocTab({ index: newValue, name: mapValue[newValue] });
  };

  return (
    <SoftBox position="relative">
      <Card
        sx={{
          backdropFilter: `saturate(200%) blur(30px)`,
          backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
            rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: "relative",
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={8} lg={8} sx={{ ml: "auto" }}>
            <SoftTypography sx={{ fontSize: 18, fontWeight: 700 }}>
              {selectedDocTab?.name} Documents
            </SoftTypography>
          </Grid>
          <Grid item xs={12} md={4} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={selectedDocTab.index}
                onChange={handleSetTabValue}
                sx={{ background: "transparent" }}
              >
                <Tab label="ISO9001" icon={<Document />} index={0} />
                <Tab label="ISO27001" icon={<Document />} index={1} />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </Card>
    </SoftBox>
  );
}

export default DocumentsHeader;
