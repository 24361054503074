import SoftBox from "components/UI/SoftBox";
import SoftButton from "components/UI/SoftButton";
import { CircularProgress, useMediaQuery } from "@mui/material";
import SoftModal from "components/UI/SoftModal";
import SoftBottomModal from "components/UI/SoftBottomModal";
import { useEffect, useState } from "react";
import InputWithLabel from "components/Inputs/InputWithLabel";
import { convertToDateObj, getUUID, responseHandler } from "utils";
import { toast } from "react-toastify";
import { useUiStateStore } from "store/ui-state";
import SoftDatePicker from "components/UI/SoftDatePicker";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addHoliday, updateHoliday } from "new-services";

export default function HolidayModal({
  title,
  open = false,
  handleClose,
  rowData,
}) {
  const [holidayData, setHolidayData] = useState({ value: "", date: "" });
  const matchesmd = useMediaQuery("(max-width:600px)");
  const { holidaysMap } = useUiStateStore();
  const ModalWrapper = matchesmd ? SoftBottomModal : SoftModal;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setHolidayData({ ...holidayData, [name]: value });
  };

  const queryClient = useQueryClient();

  const { mutate: updateHolidayData, isLoading: loadUpdate } = useMutation(
    updateHoliday,
    {
      onSuccess: (res) => {
        if (res.status_code === 200) {
          queryClient.invalidateQueries({ queryKey: ["holidays"] });
          handleClose();
        }
        responseHandler({ res });
      },
    }
  );
  const { mutate: addHolidayData, isLoading: loadAdd } = useMutation(
    addHoliday,
    {
      onSuccess: (res) => {
        if (res.status_code === 200) {
          queryClient.invalidateQueries({ queryKey: ["holidays"] });
          handleClose();
        }
        responseHandler({ res });
      },
    }
  );

  const handleSubmit = () => {
    const { status, message } = validateInput();
    if (!status && !rowData) return toast.error(message);

    const now = new Date();

    title.toLowerCase().includes("update")
      ? updateHolidayData({
          id: rowData.fid,
          payload: {
            ...holidayData,
            date: holidayData.date.toLocaleDateString("id"),
            updated_at: now,
          },
        })
      : addHolidayData({
          fid: getUUID(),
          ...holidayData,
          created_at: now,
          date: holidayData.date.toLocaleDateString("id"),
        });
  };

  useEffect(() => {
    if (rowData)
      setHolidayData({ ...rowData, date: convertToDateObj(rowData.date) });
  }, [rowData]);

  const validateInput = () => {
    if (!holidayData?.value || !holidayData?.date)
      return { status: false, message: "Please enter all input fields" };

    if (
      !rowData &&
      holidaysMap.get(holidayData?.date?.toLocaleDateString("id"))
    )
      return { status: false, message: "This date already used!" };
    return { status: true, message: "" };
  };

  return (
    <ModalWrapper title={title} open={open} handleClose={handleClose}>
      <SoftBox sx={{ mb: 1.5, mt: -1 }}>
        <InputWithLabel
          value={["value", holidayData.value]}
          onChange={handleChangeInput}
        />
        <SoftDatePicker
          value={["date", holidayData.date]}
          disableDate={(valDate) =>
            holidaysMap.get(valDate.toLocaleDateString("id"))
          }
          placeholder="Holiday Date"
          onChange={(newVal) =>
            setHolidayData({ ...holidayData, date: newVal })
          }
        />
      </SoftBox>
      <SoftBox textAlign="right">
        <SoftButton
          variant="gradient"
          color="error"
          endIcon={
            loadAdd || loadUpdate ? (
              <CircularProgress color="inherit" size={18} />
            ) : null
          }
          onClick={handleSubmit}
        >
          Submit
        </SoftButton>
      </SoftBox>
    </ModalWrapper>
  );
}
