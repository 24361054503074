import callApi from "./config";

export const login = (payload) =>
  callApi({
    method: "POST",
    endpoint: "/auth/login",
    payload,
  });

export const refreshToken = (payload) =>
  callApi({
    method: "POST",
    endpoint: "/auth/refresh-token",
    payload,
  });

export const register = (payload) =>
  callApi({
    method: "POST",
    endpoint: "/auth/register",
    payload,
  });

export const getUserInfo = (id) =>
  callApi({
    method: "GET",
    endpoint: `/users/${id}`,
  });

export const getUserProjects = (params) =>
  callApi({
    method: "GET",
    endpoint: `/projects${typeof params === "string" ? "?" + params : ""}`,
  });

export const getHolidays = () =>
  callApi({
    method: "GET",
    endpoint: `/holidays`,
  });

export const getOvertimeReport = (params) =>
  callApi({
    method: "GET",
    endpoint: `/requests/overtime-report${
      typeof params === "string" ? "?" + params : ""
    }`,
  });

export const getTools = () =>
  callApi({
    method: "GET",
    endpoint: `/tools`,
  });

export const getRoles = (params) =>
  callApi({
    method: "GET",
    endpoint: `/roles${typeof params === "string" ? "?" + params : ""}`,
  });

export const getWorkplaces = (params) =>
  callApi({
    method: "GET",
    endpoint: `/workplaces${typeof params === "string" ? "?" + params : ""}`,
  });

export const getTeams = (params) =>
  callApi({
    method: "GET",
    endpoint: `/teams${typeof params === "string" ? "?" + params : ""}`,
  });

export const getUsers = (params) =>
  callApi({
    method: "GET",
    endpoint: `/users${typeof params === "string" ? "?" + params : ""}`,
  });

export const getProgresses = (params) =>
  callApi({
    method: "GET",
    endpoint: `/progress${typeof params === "string" ? "?" + params : ""}`,
  });

export const getUserRequests = (params) =>
  callApi({
    method: "GET",
    endpoint: `/requests${typeof params === "string" ? "?" + params : ""}`,
  });

export const getUserPresences = (params) => {
  return callApi({
    method: "GET",
    endpoint: `/presences${typeof params === "string" ? "?" + params : ""}`,
  });
};

export const getUserLogbooks = (params) =>
  callApi({
    method: "GET",
    endpoint: `/logbooks${typeof params === "string" ? "?" + params : ""}`,
  });

export const getLogbookReport = (params) =>
  callApi({
    method: "GET",
    endpoint: `/logbooks/report${
      typeof params === "string" ? "?" + params : ""
    }`,
  });

export const getLogbookSummary = (params) =>
  callApi({
    method: "GET",
    endpoint: `/logbooks/summary${
      typeof params === "string" ? "?" + params : ""
    }`,
  });

export const addRequest = (payload) =>
  callApi({
    method: "POST",
    endpoint: `/requests`,
    payload,
  });

export const addHoliday = (payload) =>
  callApi({
    method: "POST",
    endpoint: `/holidays`,
    payload,
  });

export const addPresence = (payload) =>
  callApi({
    method: "POST",
    endpoint: `/presences`,
    payload,
  });

export const addProgress = (payload) =>
  callApi({
    method: "POST",
    endpoint: `/progress`,
    payload,
  });

export const addLogbook = (payload) =>
  callApi({
    method: "POST",
    endpoint: `/logbooks`,
    payload,
  });

export const addProject = (payload) =>
  callApi({
    method: "POST",
    endpoint: `/projects`,
    payload,
  });

export const addRole = (payload) =>
  callApi({
    method: "POST",
    endpoint: `/roles`,
    payload,
  });

export const addTeam = (payload) =>
  callApi({
    method: "POST",
    endpoint: `/teams`,
    payload,
  });

export const addTool = (payload) =>
  callApi({
    method: "POST",
    endpoint: `/tools`,
    payload,
  });

export const addWorkplace = (payload) =>
  callApi({
    method: "POST",
    endpoint: `/workplaces`,
    payload,
  });

export const deleteProject = (id) =>
  callApi({
    method: "DELETE",
    endpoint: `/projects/${id}`,
  });

export const deleteHoliday = (id) =>
  callApi({
    method: "DELETE",
    endpoint: `/holidays/${id}`,
  });

export const deletePresence = (id) =>
  callApi({
    method: "DELETE",
    endpoint: `/presences/${id}`,
  });

export const deleteRequest = (id) =>
  callApi({
    method: "DELETE",
    endpoint: `/requests/${id}`,
  });

export const deleteLogbook = (id) =>
  callApi({
    method: "DELETE",
    endpoint: `/logbooks/${id}`,
  });

export const deleteRole = (id) =>
  callApi({
    method: "DELETE",
    endpoint: `/roles/${id}`,
  });

export const deleteProgress = (id) =>
  callApi({
    method: "DELETE",
    endpoint: `/progress/${id}`,
  });

export const deleteUser = (id) =>
  callApi({
    method: "DELETE",
    endpoint: `/users/${id}`,
  });

export const deleteTeam = (id) =>
  callApi({
    method: "DELETE",
    endpoint: `/teams/${id}`,
  });

export const deleteWorkplace = (id) =>
  callApi({
    method: "DELETE",
    endpoint: `/workplaces/${id}`,
  });

export const updatePresence = ({ id, payload }) =>
  callApi({
    method: "PUT",
    endpoint: `/presences/${id}`,
    payload,
  });
export const updateProgress = ({ id, payload }) =>
  callApi({
    method: "PUT",
    endpoint: `/progress/${id}`,
    payload,
  });

export const updateTool = ({ id, payload }) =>
  callApi({
    method: "PUT",
    endpoint: `/tools/${id}`,
    payload,
  });

export const updateHoliday = ({ id, payload }) =>
  callApi({
    method: "PUT",
    endpoint: `/holidays/${id}`,
    payload,
  });

export const updateRole = ({ id, payload }) =>
  callApi({
    method: "PUT",
    endpoint: `/roles/${id}`,
    payload,
  });

export const updateUser = ({ id, payload }) =>
  callApi({
    method: "PUT",
    endpoint: `/users/${id}`,
    payload,
  });

export const updateTeam = ({ id, payload }) =>
  callApi({
    method: "PUT",
    endpoint: `/teams/${id}`,
    payload,
  });

export const updateWorkplace = ({ id, payload }) =>
  callApi({
    method: "PUT",
    endpoint: `/workplaces/${id}`,
    payload,
  });

export const updateLogbook = ({ id, payload }) =>
  callApi({
    method: "PUT",
    endpoint: `/logbooks/${id}`,
    payload,
  });

export const updateProject = ({ id, payload }) =>
  callApi({
    method: "PUT",
    endpoint: `/projects/${id}`,
    payload,
  });

export const updateRequest = ({ id, payload }) =>
  callApi({
    method: "PUT",
    endpoint: `/requests/${id}`,
    payload,
  });

export const getPlacesData = async (searchQuery) => {
  try {
    const result = await fetch(
      `https://geocode.maps.co/search?q=${searchQuery}`
    );
    return await result.json();
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const resetPassword = ({ payload, token }) =>
  callApi({
    method: "POST",
    endpoint: `/auth/reset-password`,
    customAuth: {
      key: "Authorization",
      value: `Bearer ${token}`,
    },
    payload,
  });

export const resetLink = (payload) =>
  callApi({
    method: "POST",
    endpoint: `/auth/send-reset-link`,
    payload,
  });

export const checkPassword = () =>
  callApi({
    method: "GET",
    endpoint: `/auth/check-password`,
  });
