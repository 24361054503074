import { Tab } from "@headlessui/react";
import {
  CircularProgress,
  Divider,
  Grid,
  Skeleton,
  Stack,
} from "@mui/material";
import SelectOption from "components/UI/SelectOption";
import SoftButton from "components/UI/SoftButton";
import SoftTypography from "components/UI/SoftTypography";
import FilterData from "layouts/reports/components/FilterData";
import React, { useState } from "react";
import { useUiStateStore } from "store/ui-state";
import { exportMultipleWs, exportSingleWs, formatDate } from "utils";

import styles from "./timesheet.module.css";
import TimeSheetTable from "components/Tables/TimesheetTable";
import { NewReleases } from "@mui/icons-material";
import UserTab from "./UserTab";

const TimeSheet = () => {
  const [templateType, setTemplateType] = useState("DDB/EDM");
  const [filterData, setFiltereData] = useState({});
  const { globalLoading, exportData } = useUiStateStore();
  const onSetFilter = (filter) => setFiltereData(filter);

  const handleGenerateReport = () =>
    exportMultipleWs(
      exportData,
      templateType,
      `Timesheet_${
        filterData.user.name
          ? filterData.user.name
          : filterData.team.name
          ? filterData.team.name
          : filterData.workplace.name
      }_${templateType} format_${formatDate(
        filterData.startDate
      )} - ${formatDate(filterData.endDate)}`
    );

  return (
    <>
      <SoftTypography my={1.5} fontWeight="bold">
        Report Timesheet
      </SoftTypography>
      <FilterData onSetFilter={onSetFilter} />
      <Stack mt={1.5} mb={1}>
        <SoftTypography fontSize={16} fontWeight="bold">
          Select & Export Report Template
        </SoftTypography>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <SelectOption
            prefix="Select"
            noLabel
            value={["report_template", templateType]}
            options={[
              { key: "BRI EDM/DDB", value: "DDB/EDM" },
              { key: "BRI Ragunan", value: "Ragunan" },
            ]}
            onSelect={(e) => setTemplateType(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SoftButton
            disabled={
              exportData.length === 0 || !filterData?.workplace || globalLoading
            }
            variant="gradient"
            color="error"
            onClick={handleGenerateReport}
          >
            {!filterData?.workplace
              ? "Filter Not Applied"
              : exportData.length === 0
              ? "No Data To Export"
              : "Export All Report"}
          </SoftButton>
        </Grid>
      </Grid>
      {exportData.length === 0 ? (
        <Stack
          alignItems="center"
          spacing={2}
          justifyContent="center"
          mt={5}
          sx={{ fontSize: "60px" }}
        >
          <NewReleases />
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            {globalLoading && <CircularProgress color="inherit" size={18} />}
            <SoftTypography fontSize={16}>
              {!filterData?.workplace
                ? "Filter not applied, Please apply filter first !"
                : globalLoading
                ? "Initialize Filtering Data ..."
                : exportData.length === 0 && "Data not available !"}
            </SoftTypography>
          </Stack>
        </Stack>
      ) : (
        <Tab.Group>
          <Tab.List className={styles.tabs}>
            {globalLoading
              ? Array(5)
                  .fill()
                  .map((a, idx) => (
                    <Skeleton
                      key={idx}
                      variant="rounded"
                      sx={{
                        height: 50,
                      }}
                    />
                  ))
              : exportData?.map((data, i) => <UserTab data={data} key={i} />)}
          </Tab.List>
          <Divider />
          <Tab.Panels>
            {exportData.length > 0 &&
              exportData?.map((d, i) => (
                <Tab.Panel key={i}>
                  {globalLoading ? (
                    <Stack spacing={1}>
                      <Skeleton
                        variant="rounded"
                        sx={{
                          height: 50,
                        }}
                      />
                      <Skeleton
                        variant="rounded"
                        sx={{
                          height: 500,
                        }}
                      />
                    </Stack>
                  ) : (
                    <TimeSheetTable
                      rows={d.data}
                      tableProps={{
                        isLoading: globalLoading,
                        withExport: true,
                        pageStart: 10,
                        exportFn: () =>
                          exportSingleWs(
                            d,
                            templateType,
                            `Timeheet_${
                              d.user_name
                            }_${templateType} format_${formatDate(
                              filterData.startDate
                            )} - ${formatDate(filterData.endDate)}`
                          ),
                      }}
                      tableTitle={`${d.user_name}'s Timesheet`}
                    />
                  )}
                </Tab.Panel>
              ))}
          </Tab.Panels>
        </Tab.Group>
      )}
    </>
  );
};

export default TimeSheet;
