import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useUiStateStore } from "store/ui-state";
import FallbackUI from "./fallback-ui";

const withAdminAuth = (Component, allowedCompanyRoles = []) => {
  return (props) => {
    const {
      userLoggedIn: { userInfo },
    } = useUiStateStore();
    const navigate = useNavigate();

    // Check if user is administrator
    const isAdmin = userInfo?.system_role?.toLowerCase() === "administrator";

    // Check if user has allowed company role
    const hasAllowedCompanyRole =
      allowedCompanyRoles.length === 0 ||
      allowedCompanyRoles.includes(userInfo?.company_role?.name);

    // Single condition to check authorization
    const isAuthorized = isAdmin || hasAllowedCompanyRole;

    useEffect(() => {
      if (!isAuthorized) {
        toast.error("Forbidden Access!");
        navigate("/profile");
      }
    }, [isAuthorized, navigate]);

    if (!isAuthorized) {
      return <FallbackUI />;
    }

    return <Component {...props} />;
  };
};

export default withAdminAuth;
