import { toast } from "react-toastify";
import { format } from "date-fns";
import { utils, writeFile } from "xlsx-js-style";
import Fuse from "fuse.js";
import {
  SUMMARY_STYLE,
  DEFAULT_FOOTER_STYLE,
  DEFAULT_BORDER_STYLE,
  CENTER_CONTENT_WRAP_STYLE,
  WRAP_CONTENT_STYLE,
  DEFAULT_HEADER_STYLE,
  THEME_STORAGE,
  MAP_CHIP_DATA,
  NON_ADMIN_ROUTES,
} from "constants";
import Cookies from "js-cookie";
import { refreshToken } from "new-services";

const initTPeriod = {
  period: 15,
  startDate: "2023-05-11",
  endDate: "2023-06-10",
};

export const todayMidnight = () => {
  const now = new Date();
  const todayMidnight = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    0,
    0,
    0,
    0
  );
  return todayMidnight;
};
const getMonthDiff = (queryDate) => {
  const tanggalAwal = new Date(initTPeriod.startDate);

  let tanggalSaatIni = new Date();

  if (queryDate) {
    tanggalSaatIni = new Date(queryDate);
  }

  var selisihBulan =
    (tanggalSaatIni.getFullYear() - tanggalAwal.getFullYear()) * 12 +
    tanggalSaatIni.getMonth() -
    tanggalAwal.getMonth();

  return selisihBulan;
};

const exportExcel = (data, filename) => {
  const wb = utils.book_new();
  const ws = utils.json_to_sheet(data);
  utils.book_append_sheet(wb, ws, "Sheet1");
  writeFile(wb, filename ? `${filename}.xlsx` : "export.xlsx");
};

const formatDate = (value, type) => {
  var d = new Date(value),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return type === "local"
    ? [day, month, year].join("-")
    : [year, month, day].join("-");
};

let isRefresh = false;
const responseHandler = ({ res, cb, successMessage, errorMessage, type }) => {
  if (res?.status_code < 300) {
    type !== "GET" && toast.success(successMessage || res?.message);
  } else if (
    res.status_code === 401 &&
    (res.message === "Invalid access token" ||
      res.message === "Token has expired")
  ) {
    if (type === "GET" && isRefresh) return;
    if (type === "GET") isRefresh = true;
    (async () => {
      const res = await refreshToken({
        refresh_token: convertBase64("decode", Cookies.get("refresh_token")),
        user_id: JSON.parse(sessionStorage.getItem("authUser"))?.user_id,
      });
      if (res.status_code === 200) {
        Cookies.set("access_token", convertBase64("encode", res.data));
        window.location.reload();
      }
      if (res.status_code === 401) {
        toast.error("Session expired, will be redirected back to login");
        setTimeout(() => {
          sessionStorage.clear();
          Cookies.remove("access_token");
          Cookies.remove("refresh_token");
          window.location.replace("/authentication/sign-in");
        }, 3000);
      }
    })();
  } else {
    toast.error(errorMessage || res?.message || "Something went wrong !");
  }
  cb && cb();
  return res?.data;
};

const convertBase64 = (type, val) => {
  try {
    return type === "encode" ? btoa(val) : atob(val);
  } catch (e) {
    toast.error(e.message);
  }
};

const updateTHEME_STORAGE = (key, val) => {
  let currentStorage = JSON.parse(THEME_STORAGE);
  if (!currentStorage) currentStorage = {};
  currentStorage[key] = val;
  localStorage.setItem("THEME_STORAGE", JSON.stringify(currentStorage));
  return val;
};

const validateInputField = (input) => {
  const mandatoryList = [
    "username",
    "password",
    "role_id",
    "phone_number",
    "bank_name",
    "account_number",
    "category",
    "ammount",
    "old_password",
    "new_password",
    "status",
    "type_id",
    "bank_id",
  ];

  const validate = (input, message) => {
    for (let [key, val] of Object.entries(input)) {
      if (!val && mandatoryList.includes(key)) {
        toast.error(message || `${formatKey(key)} is required !`);
        return false;
      }
    }
    return true;
  };

  if (input.length) {
    let valToValidate = {};
    for (let value of input) {
      valToValidate["VALUE"] = value.VALUE;
    }
    return validate(valToValidate, "All inputs must be filled in !");
  } else {
    return validate(input);
  }
};

const upperFirstChar = (val) => val.charAt(0).toUpperCase() + val.slice(1);

const formatKey = (key) =>
  key
    .split("_")
    .map((val) => upperFirstChar(val))
    .join(" ");

const inputType = (val) => {
  return val.includes("index") ||
    val.includes("number") ||
    val.includes("balance")
    ? "number"
    : val.includes("password")
    ? "password"
    : "text";
};

const toastErrorMessage = (res) =>
  (typeof res.error === "string"
    ? res.error
    : res.message || `${res.error[0].field}, ${res.error[0].message}`) ||
  "Something went wrong";

const formatDateID = (date) => {
  const dateJS = new Date(date);
  return format(dateJS, "EEE, dd LLL yyyy, HH:mm");
};

const currencyFormat = (format, number, withDecimal = true) => {
  let result = 0;
  if (typeof number === "number") {
    if (withDecimal) {
      result = new Intl.NumberFormat(format, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(number);
    } else {
      result = new Intl.NumberFormat(format).format(number);
    }
  }

  switch (format) {
    case "ID":
      return `Rp${result}`;
    default:
      return result;
  }
};

const countTotalBalance = (coins) =>
  coins.reduce((acc, curr) => acc + curr.balance, 0);

const filterNonAdminRoutes = (routes) =>
  routes.filter((route) => NON_ADMIN_ROUTES.includes(route.route));

const transformTimeStamp = (timestamp) => new Date(timestamp * 1000);

const validateClockOut = (clock_in, clock_out) =>
  new Date(clock_in.seconds * 1000 + 8 * 3600 * 1000) <= Date.now();

export {
  validateClockOut,
  formatDate,
  transformTimeStamp,
  responseHandler,
  convertBase64,
  updateTHEME_STORAGE,
  inputType,
  formatKey,
  upperFirstChar,
  validateInputField,
  toastErrorMessage,
  formatDateID,
  currencyFormat,
  exportExcel,
  filterNonAdminRoutes,
  countTotalBalance,
};

export function getUUID() {
  // eslint gets funny about bitwise
  /* eslint-disable */
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const piece = (Math.random() * 16) | 0;
    const elem = c === "x" ? piece : (piece & 0x3) | 0x8;
    return elem.toString(16);
  });
  /* eslint-enable */
}

export function getLocation(setLocation) {
  if (navigator.geolocation) {
    return navigator.geolocation.getCurrentPosition(
      (location) => setLocation(location),
      (error) => {
        toast.error(`Error: ${error.message}`);
        setLocation({});
      }
    );
  } else {
    toast.error("Geolocation is not supported on this browser");
    setLocation({});
  }
}

export const getPresenceToday = (presenceInfo) => {
  const today = new Date();
  const presenceMap = new Map(
    presenceInfo.map((pres) => [
      convertTimeFromDate.toDateString(pres.created_at),
      pres,
    ])
  );
  return presenceMap.get(convertTimeFromDate.toDateString(today));
};

export const getLogbookToday = (logbookInfo) => {
  const today = new Date();

  const loogbookMap = new Map(
    logbookInfo.map((pres) => [
      convertTimeFromDate.toDateString(pres.created_at),
      pres,
    ])
  );

  return loogbookMap.get(convertTimeFromDate.toDateString(today));
};

export const generateArray = (length, value) => Array(length).fill(value);

export function arePointsNear(checkPoint, centerPoint, km = 70) {
  var ky = 40000 / 360;
  var kx = Math.cos((Math.PI * Number(centerPoint.lat)) / 180.0) * ky;
  var dx = Math.abs(Number(centerPoint.lng) - Number(checkPoint.lng)) * kx;
  var dy = Math.abs(Number(centerPoint.lat) - Number(checkPoint.lat)) * ky;
  return Math.sqrt(dx * dx + dy * dy) <= km / 1000;
}

export const getColorPercentage = (value) => {
  if (value < 50) return "red";
  if (value >= 50 && value <= 99) return "#F9D949";
  if (value === 100) return "#54B435";
};

export const convertTime = {
  toDateString: (time_seconds, type) =>
    new Date(time_seconds * 1000).toDateString(type),
  toLocaleTimeString: (time_seconds, type) =>
    new Date(time_seconds * 1000).toLocaleTimeString(type, {
      hour: "2-digit",
      minute: "2-digit",
    }),
  toLocaleDateString: (time_seconds, type) =>
    new Date(time_seconds * 1000).toLocaleDateString(type || "ID"),
};

export const convertTimeFromDate = {
  toDateString: (date, type) => (date ? new Date(date).toDateString(type) : ""),
  toLocaleTimeString: (date, type) =>
    date
      ? new Date(date).toLocaleTimeString(type, {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        })
      : "",
  toLocaleDateString: (date, type) =>
    date ? new Date(date).toLocaleDateString(type || "ID") : "",
};

export const convertToHoursMinutes = (timestamp) => {
  // Handle negative timestamps
  const isNegative = timestamp < 0;
  const absTimestamp = Math.abs(timestamp);

  const hours = Math.floor(absTimestamp / 3600);
  const minutes = Math.floor((absTimestamp % 3600) / 60);

  // Format the result with a minus sign if original was negative
  return `${isNegative ? "-" : ""}${hours} h ${minutes} m`;
};
export const countTotalHours = (start, end) => {
  // Calculate the time difference in seconds
  const timeDiffSeconds = end - start;

  // Format the hours and minutes as a string
  const timeDiffString = convertToHoursMinutes(timeDiffSeconds);

  return timeDiffString;
};

export const isLtEightHours = (start, end) => {
  return (end - start) / 3600 < 9;
};

export const exportDDBEDMTemlplate = (wb, data, otherInfo) => {
  let ws;
  let populateData = [];
  const userData = data.find((d) => d?.user);

  for (let value of data) {
    let fillData = [];
    for (let i = 0; i < 9; i++) {
      if (i === 0) {
        fillData.push({
          v: value.date,
          s: {
            ...CENTER_CONTENT_WRAP_STYLE,
            fill: value?.value ? { fgColor: { rgb: "9BA4B5" } } : undefined,
          },
        });
        continue;
      }
      if (i === 1) {
        fillData.push({
          v: value?.work_mode || value?.value,
          s: {
            ...CENTER_CONTENT_WRAP_STYLE,
            fill: value?.value ? { fgColor: { rgb: "9BA4B5" } } : undefined,
          },
        });
        continue;
      }
      if (i === 2) {
        fillData.push({
          v: value?.clock_in || "",
          s: {
            ...CENTER_CONTENT_WRAP_STYLE,
            fill: value?.value
              ? { fgColor: { rgb: "9BA4B5" } }
              : value?.isLtEightHours
              ? { fgColor: { rgb: "F45050" } }
              : undefined,
          },
        });
        continue;
      }
      if (i === 3) {
        fillData.push({
          v: value?.clock_out || "",
          s: {
            ...CENTER_CONTENT_WRAP_STYLE,
            fill: value?.value
              ? { fgColor: { rgb: "9BA4B5" } }
              : value?.isLtEightHours
              ? { fgColor: { rgb: "F45050" } }
              : undefined,
          },
        });
        continue;
      }

      if (i === 6) {
        fillData.push({
          v: value?.activity || value?.value || "",
          s: {
            ...WRAP_CONTENT_STYLE,
            fill: value?.value ? { fgColor: { rgb: "9BA4B5" } } : undefined,
          },
        });
        continue;
      }

      if (i === 7) {
        fillData.push({
          v: value?.usecase || value?.value || "",
          s: {
            ...WRAP_CONTENT_STYLE,
            fill: value?.value ? { fgColor: { rgb: "9BA4B5" } } : undefined,
          },
        });
        continue;
      }

      if (i === 8) {
        // fillData.push({
        //   v: value.next_activity || value?.value || "",
        //   s: {
        //     ...WRAP_CONTENT_STYLE,
        //     fill: value?.value ? { fgColor: { rgb: "9BA4B5" } } : undefined,
        //   },
        // });
        continue;
      }
      fillData.push({
        v: "-",
        s: {
          ...CENTER_CONTENT_WRAP_STYLE,
          fill: value?.value ? { fgColor: { rgb: "9BA4B5" } } : undefined,
        },
      });
    }
    populateData.push(fillData);
  }

  const projects = Array.from(
    new Set(data.filter((d) => d?.project).map((d) => d.project))
  ).join(", ");
  const aoaData = [
    [
      {
        v: "ABSENSI DAN DAILY REPORT PERSONAL",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 16, bold: true },
        },
      },
      ...generateArray(8, ""),
    ],
    generateArray(8, ""),
    [
      {
        v: "Person",
        s: {
          font: { sz: 14 },
        },
      },
      {
        v: userData?.user,
        s: {
          font: { sz: 14 },
        },
      },
      ...generateArray(7, ""),
    ],
    [
      {
        v: "Team",
        s: {
          font: { sz: 14 },
        },
      },
      {
        v: userData?.team,
        s: {
          font: { sz: 14 },
        },
      },
      ...generateArray(7, ""),
    ],
    [
      {
        v: "Project",
        s: {
          alignment: { vertical: "center", wrapText: true },
          font: { sz: 14 },
        },
      },
      {
        v: projects,
        s: {
          font: { sz: 14 },
        },
      },
      ...generateArray(7, ""),
    ],
    generateArray(8, ""),
    [
      {
        v: "Tanggal",
        s: DEFAULT_HEADER_STYLE,
      },
      {
        v: "Absen",
        s: DEFAULT_HEADER_STYLE,
      },
      {
        v: "Jam Masuk",
        s: DEFAULT_HEADER_STYLE,
      },
      {
        v: "Jam Keluar",
        s: DEFAULT_HEADER_STYLE,
      },
      {
        v: "Lembur",
        s: DEFAULT_HEADER_STYLE,
      },
      "",
      {
        v: "Aktivitas Hari Ini",
        s: DEFAULT_HEADER_STYLE,
      },
      {
        v: "Nama Use Case / Key Result",
        s: DEFAULT_HEADER_STYLE,
      },

      // {
      //   v: "Rencana Selanjutnya",
      //   s: DEFAULT_HEADER_STYLE,
      // },
    ],
    [
      ...generateArray(4, ""),
      {
        v: "Jam Mulai",
        s: DEFAULT_HEADER_STYLE,
      },
      {
        v: "Jam Selesai",
        s: DEFAULT_HEADER_STYLE,
      },
      ...generateArray(2, ""),
    ],
    ...populateData,
    generateArray(8, ""),
    generateArray(8, ""),
    generateArray(8, ""),
    [
      ...generateArray(6, ""),
      {
        v: "Team Leader",
        s: { ...DEFAULT_HEADER_STYLE, border: DEFAULT_BORDER_STYLE },
      },
      {
        v: "Team Member",
        s: { ...DEFAULT_HEADER_STYLE, border: DEFAULT_BORDER_STYLE },
      },
    ],
    [
      ...generateArray(6, ""),
      {
        v: otherInfo?.team_lead || "",
        s: {
          border: DEFAULT_BORDER_STYLE,
          alignment: { horizontal: "center" },
          font: { sz: 13 },
        },
      },
      {
        v: userData?.user,
        s: {
          alignment: { horizontal: "center" },
          border: DEFAULT_BORDER_STYLE,
          font: { sz: 13 },
        },
      },
    ],
  ];
  ws = utils.aoa_to_sheet(aoaData);
  const colWidth = [85, 85, 85, 85, 85, 85, 200, 200];

  colWidth.forEach((width, columnIndex) => {
    ws["!cols"] = ws["!cols"] || [];
    ws["!cols"][columnIndex] = { hidden: false, wpx: width };
  });
  if (!ws["!rows"]) ws["!rows"] = [];
  ws["!rows"][aoaData.length - 2] = { hpt: 25, hpx: 25 };
  ws["!rows"][aoaData.length - 1] = { hpt: 80, hpx: 80 };

  const startRow = 0; // Baris awal range
  const endRow = aoaData.length - 6; // Baris akhir range
  const startCol = 0; // Kolom awal range
  const endCol = 7; // Kolom akhir range

  for (let row = startRow; row <= endRow; row++) {
    for (let col = startCol; col <= endCol; col++) {
      const cellAddress = utils.encode_cell({ r: row, c: col });
      const cell = ws[cellAddress];
      if (!cell) continue;

      cell.s = {
        ...cell.s,
        border: DEFAULT_BORDER_STYLE,
      };
    }
  }

  if (!ws["!merges"]) ws["!merges"] = [];
  ws["!merges"].push(utils.decode_range("A1:H1"));
  ws["!merges"].push(utils.decode_range("A2:H2"));
  ws["!merges"].push(utils.decode_range("B3:H3"));
  ws["!merges"].push(utils.decode_range("B4:H4"));
  ws["!merges"].push(utils.decode_range("B5:H5"));
  ws["!merges"].push(utils.decode_range("A6:H6"));
  ws["!merges"].push(utils.decode_range("A7:A8"));
  ws["!merges"].push(utils.decode_range("B7:B8"));
  ws["!merges"].push(utils.decode_range("C7:C8"));
  ws["!merges"].push(utils.decode_range("D7:D8"));
  ws["!merges"].push(utils.decode_range("E7:F7"));
  ws["!merges"].push(utils.decode_range("G7:G8"));
  ws["!merges"].push(utils.decode_range("H7:H8"));
  ws["!merges"].push(utils.decode_range("I7:I8"));
  ws["!merges"].push(
    utils.decode_range(`A${aoaData.length - 1}:F${aoaData.length - 1}`)
  );
  ws["!merges"].push(
    utils.decode_range(`A${aoaData.length}:F${aoaData.length}`)
  );
  utils.book_append_sheet(wb, ws, userData?.user?.slice(0, 30));
};

export const exportRagunanTemlplate = (wb, data, otherInfo) => {
  let ws;
  let populateData = [];
  const userData = data.find((d) => d?.user);

  for (let [index, value] of data.entries()) {
    let fillData = [];
    for (let i = 0; i < 13; i++) {
      if (i === 0) {
        fillData.push({
          v: index + 1,
          s: CENTER_CONTENT_WRAP_STYLE,
        });
        continue;
      }
      if (i === 1) {
        fillData.push({
          v: value?.date,
          s: CENTER_CONTENT_WRAP_STYLE,
        });
        continue;
      }
      if (i === 2) {
        fillData.push({
          v: value?.project || value?.value || "",
          s: {
            ...WRAP_CONTENT_STYLE,
            alignment: { vertical: "center", wrapText: true },
            font: { bold: true },
            fill: value?.value ? { fgColor: { rgb: "FFED00" } } : undefined,
          },
        });
        continue;
      }
      if (i === 3) {
        fillData.push({
          v: value?.work_mode === "WFO" ? "GTI" : value?.work_mode || "",
          s: {
            ...CENTER_CONTENT_WRAP_STYLE,
            font: { bold: true },
            fill: value?.value ? { fgColor: { rgb: "FFED00" } } : undefined,
          },
        });
        continue;
      }
      if (i === 4) {
        fillData.push({
          v: value?.clock_in || "",
          s: {
            ...CENTER_CONTENT_WRAP_STYLE,
            font: { bold: true },
            fill: value?.value
              ? { fgColor: { rgb: "FFED00" } }
              : value?.isLtEightHours
              ? { fgColor: { rgb: "F45050" } }
              : undefined,
          },
        });
        continue;
      }

      if (i === 6) {
        fillData.push({
          v: value?.clock_out || "",
          s: {
            ...CENTER_CONTENT_WRAP_STYLE,
            font: { bold: true },
            fill: value?.value
              ? { fgColor: { rgb: "FFED00" } }
              : value?.isLtEightHours
              ? { fgColor: { rgb: "F45050" } }
              : undefined,
          },
        });
        continue;
      }

      if (i === 10) {
        fillData.push({
          v: value?.hoursPerDay || "",
          s: {
            ...CENTER_CONTENT_WRAP_STYLE,
            fill: value?.value ? { fgColor: { rgb: "FFED00" } } : undefined,
          },
        });
        continue;
      }
      if (i === 12) {
        fillData.push({
          v: value?.activity || "",
          s: {
            ...WRAP_CONTENT_STYLE,
            fill: value?.value ? { fgColor: { rgb: "FFED00" } } : undefined,
          },
        });
        continue;
      }
      fillData.push({
        v: "",
        s: {
          ...CENTER_CONTENT_WRAP_STYLE,
          fill: value?.value ? { fgColor: { rgb: "FFED00" } } : undefined,
        },
      });
    }
    populateData.push(fillData);
  }

  const aoaData = [
    [
      {
        v: "NAME",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 14, color: { rgb: "FFFFFF" }, bold: true },
          fill: { fgColor: { rgb: "000000" } },
        },
      },
      "",
      {
        v: userData?.user,
        s: {
          alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText: true,
          },
          font: { sz: 14, bold: true },
        },
      },
      "",
      {
        v: "JOB",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 14, color: { rgb: "FFFFFF" }, bold: true },
          fill: { fgColor: { rgb: "000000" } },
        },
      },
      "",
      "",
      {
        v: userData?.role,
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 14, bold: true },
        },
      },
      "",
      "",
      "",
      "",
      {
        v: "PT SHARING VISION INDONESIA",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 14, bold: true },
        },
      },
    ],
    [
      {
        v: "NO",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 11, bold: true },
        },
      },
      {
        v: "DATE",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 11, bold: true },
        },
      },
      {
        v: "NAME OF PROJECT",
        s: {
          alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText: true,
          },
          font: { sz: 11, bold: true },
        },
      },
      {
        v: "LOCATION",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 11, bold: true },
        },
      },
      {
        v: "WORKING HOUR",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 11, color: { rgb: "FFFFFF" }, bold: true },
          fill: { fgColor: { rgb: "000000" } },
        },
      },
      "",
      "",
      {
        v: "OVERTIME",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 11, color: { rgb: "FFFFFF" }, bold: true },
          fill: { fgColor: { rgb: "000000" } },
        },
      },
      "",
      "",
      {
        v: "TOTAL",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 11, color: { rgb: "FFFFFF" }, bold: true },
          fill: { fgColor: { rgb: "000000" } },
        },
      },
      "",
      {
        v: "ACTIVITY/REMARK",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 12, bold: true },
        },
      },
    ],
    [
      ...generateArray(4, ""),
      {
        v: "Start",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 10, bold: true },
        },
      },
      {
        v: "-",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 10, bold: true },
        },
      },
      {
        v: "End",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 10, bold: true },
        },
      },
      {
        v: "Start",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 10, bold: true },
        },
      },
      {
        v: "-",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 10, bold: true },
        },
      },
      {
        v: "End",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 10, bold: true },
        },
      },
      {
        v: "Hour",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 10, bold: true },
        },
      },
      {
        v: "OT",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 10, bold: true },
        },
      },
      "",
    ],
    ...populateData,
    [
      { v: "TOTAL HOURS", s: DEFAULT_FOOTER_STYLE },
      ...generateArray(10, ""),
      {
        v: convertToHoursMinutes(
          data
            .filter((d) => d?.timeDiff)
            .reduce((acc, d) => acc + d.timeDiff, 0)
        ),
        s: DEFAULT_FOOTER_STYLE,
      },
      { v: "PERNYATAAN SAYA", s: DEFAULT_FOOTER_STYLE },
    ],
    [
      { v: "A", s: SUMMARY_STYLE },
      { v: "Ijin" },
      ...generateArray(9, ""),
      { v: "0", s: SUMMARY_STYLE },
      {
        v: "Time report ini saya buat dengan sunguh-sungguh dan sebenarnya sesuai dengan nilai-nilai etika dan profesionalisme perusahaan dengan penempatan di BRI",
        s: SUMMARY_STYLE,
      },
    ],
    [
      { v: "B", s: SUMMARY_STYLE },
      { v: "Sakit" },
      ...generateArray(9, ""),
      {
        v: data.filter((d) => d?.work_mode?.includes("Sakit")).length,
        s: SUMMARY_STYLE,
      },
      "",
    ],
    [
      { v: "C", s: SUMMARY_STYLE },
      { v: "Cuti" },
      ...generateArray(9, ""),
      {
        v: data.filter((d) => d?.work_mode?.includes("Cuti")).length,
        s: SUMMARY_STYLE,
      },
      "",
    ],

    [
      { v: "D", s: SUMMARY_STYLE },
      { v: "Total Jam Kerja Normal" },
      ...generateArray(9, ""),
      {
        v: data.filter((d) => !d?.value).length * 8,
        s: SUMMARY_STYLE,
      },
      "",
    ],
    [
      { v: "E", s: SUMMARY_STYLE },
      { v: "Total Hari Kerja" },
      ...generateArray(9, ""),
      {
        v: data.filter((d) => !d?.value).length,
        s: SUMMARY_STYLE,
      },
      "",
    ],
    [
      { v: "F", s: SUMMARY_STYLE },
      { v: "Total Jam Kerja Lembur" },
      ...generateArray(9, ""),
      {
        v: "0",
        s: SUMMARY_STYLE,
      },
      "",
    ],
    [
      { v: "G", s: SUMMARY_STYLE },
      { v: "Persentase Keterlambatan" },
      ...generateArray(9, ""),
      {
        v: "0%",
        s: SUMMARY_STYLE,
      },
      "",
    ],
    [
      { v: "H", s: SUMMARY_STYLE },
      { v: "Persentase Doa Pagi" },
      ...generateArray(9, ""),
      "",
      "",
    ],
  ];

  ws = utils.aoa_to_sheet(aoaData);
  const colWidth = [30, 80, 95, 75, ...generateArray(8, 70), 350];

  colWidth.forEach((width, columnIndex) => {
    ws["!cols"] = ws["!cols"] || [];
    ws["!cols"][columnIndex] = { hidden: false, wpx: width };
  });
  if (!ws["!rows"]) ws["!rows"] = [];
  ws["!rows"][0] = { hpt: 25, hpx: 25 };
  ws["!rows"][1] = { hpt: 25, hpx: 25 };
  ws["!rows"][2] = { hpt: 20, hpx: 20 };
  ws["!rows"][data.length + 3] = { hpt: 25, hpx: 25 };

  // Border All
  const startRow = 0; // Baris awal range
  const endRow = aoaData.length; // Baris akhir range
  const startCol = 0; // Kolom awal range
  const endCol = 12; // Kolom akhir range

  for (let row = startRow; row <= endRow; row++) {
    for (let col = startCol; col <= endCol; col++) {
      const cellAddress = utils.encode_cell({ r: row, c: col });
      const cell = ws[cellAddress];
      if (!cell) continue;

      cell.s = {
        ...cell.s,
        border: DEFAULT_BORDER_STYLE,
      };
    }
  }

  if (!ws["!merges"]) ws["!merges"] = [];
  ws["!merges"].push(utils.decode_range("A1:B1"));
  ws["!merges"].push(utils.decode_range("C1:D1"));
  ws["!merges"].push(utils.decode_range("E1:G1"));
  ws["!merges"].push(utils.decode_range("H1:L1"));
  ws["!merges"].push(utils.decode_range("A2:A3"));
  ws["!merges"].push(utils.decode_range("B2:B3"));
  ws["!merges"].push(utils.decode_range("C2:C3"));
  ws["!merges"].push(utils.decode_range("D2:D3"));
  ws["!merges"].push(utils.decode_range("E2:G2"));
  ws["!merges"].push(utils.decode_range("H2:J2"));
  ws["!merges"].push(utils.decode_range("K2:L2"));
  ws["!merges"].push(utils.decode_range("M2:M3"));
  for (const [index, value] of data.entries()) {
    if (value?.value)
      ws["!merges"].push(utils.decode_range(`C${index + 4}: M${index + 4}`));
  }
  ws["!merges"].push(
    utils.decode_range(`A${data.length + 4}:K${data.length + 4}`)
  );

  for (let i = 5; i <= 12; i++) {
    ws["!merges"].push(
      utils.decode_range(`B${data.length + i}:C${data.length + i}`)
    );
  }
  ws["!merges"].push(
    utils.decode_range(`D${data.length + 5}:K${data.length + 12}`)
  );
  ws["!merges"].push(
    utils.decode_range(`M${data.length + 5}:M${data.length + 12}`)
  );

  utils.book_append_sheet(wb, ws, userData?.user?.slice(0, 30));
};

export const exportAlloBITemlplate = (wb, data, filename) => {
  let ws;
  let populateData = [];
  const userData = data.find((d) => d?.user);

  for (let [, value] of data.entries()) {
    let fillData = [];
    for (let i = 0; i < 15; i++) {
      if (i === 0) {
        fillData.push({
          v: value?.date,
          s: {
            ...CENTER_CONTENT_WRAP_STYLE,
            font: { bold: true.valueOf, sz: 12 },
          },
        });
        continue;
      }

      if (i === 1) {
        fillData.push({
          v: value?.clock_in || "",
          s: {
            ...CENTER_CONTENT_WRAP_STYLE,
            font: { bold: true.valueOf, sz: 12 },
            fill: value?.value
              ? { fgColor: { rgb: "FFED00" } }
              : value?.isLtEightHours
              ? { fgColor: { rgb: "F45050" } }
              : undefined,
          },
        });
        continue;
      }

      if (i === 3) {
        fillData.push({
          v: value?.clock_out || "",
          s: {
            ...CENTER_CONTENT_WRAP_STYLE,
            font: { bold: true.valueOf, sz: 12 },
            fill: value?.value
              ? { fgColor: { rgb: "FFED00" } }
              : value?.isLtEightHours
              ? { fgColor: { rgb: "F45050" } }
              : undefined,
          },
        });
        continue;
      }

      if (i === 7) {
        fillData.push({
          v: value?.hoursPerDay || "",
          s: {
            ...CENTER_CONTENT_WRAP_STYLE,
            font: { bold: true.valueOf, sz: 12 },
            fill: value?.value
              ? { fgColor: { rgb: "FFED00" } }
              : value?.isLtEightHours
              ? { fgColor: { rgb: "F45050" } }
              : undefined,
          },
        });
        continue;
      }

      if (i === 9) {
        fillData.push({
          v: value?.work_mode ? value?.work_mode : value?.value ? "Libur" : "",
          s: {
            ...CENTER_CONTENT_WRAP_STYLE,
            font: { bold: true.valueOf, sz: 12 },
            fill: value?.value ? { fgColor: { rgb: "FFED00" } } : undefined,
          },
        });
        continue;
      }

      if (i === 10) {
        fillData.push({
          v: value?.activity || value?.value || "",
          s: {
            ...WRAP_CONTENT_STYLE,
            alignment: { vertical: "center", wrapText: true },
            font: {
              sz: 12,
              bold: value?.value ? true : false,
            },
            fill: value?.value ? { fgColor: { rgb: "FFED00" } } : undefined,
          },
        });
        continue;
      }

      fillData.push({
        v: "",
        s: {
          ...CENTER_CONTENT_WRAP_STYLE,
          fill: value?.value ? { fgColor: { rgb: "FFED00" } } : undefined,
        },
      });
    }
    populateData.push(fillData);
  }

  const aoaData = [
    [
      {
        v: "Name Of Project",
        s: {
          alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText: true,
          },
          font: { sz: 12, bold: true },
        },
      },
      {
        v: "Location",
        s: {
          alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText: true,
          },
          font: { sz: 12, bold: true },
        },
      },
      ...generateArray(2, ""),
      {
        v: `Nama: \n${userData.user}`,
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 12, bold: true },
        },
      },
      ...generateArray(4, ""),
      {
        v: "Image",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 12, bold: true },
        },
      },
      {
        v: `Periode: \n${userData.user}`,
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 12, bold: true },
        },
      },
      ...generateArray(4, ""),
    ],
    [
      {
        v: "Allo Bank",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 12, bold: true },
        },
      },
      {
        v: "Alamat",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 12, bold: true },
        },
      },
      ...generateArray(2, ""),
      {
        v: "SV ID:",
        s: {
          alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText: true,
          },
          font: { sz: 12, bold: true },
        },
      },
      ...generateArray(10, ""),
    ],
    [
      {
        v: "Date",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 12, bold: true },
        },
      },
      {
        v: "Working Hour",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 12, bold: true },
        },
      },
      ...generateArray(2, ""),
      {
        v: "Overtime",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 12, bold: true },
        },
      },
      ...generateArray(2, ""),
      {
        v: "Total Hours",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 12, bold: true },
        },
      },
      {
        v: "Total OT",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 12, bold: true },
        },
      },
      {
        v: "Status",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 12, bold: true },
        },
      },
      {
        v: "Activity/Remark",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 12, bold: true },
        },
      },
      ...generateArray(4, ""),
    ],
    [
      "",
      {
        v: "Start",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 12, bold: true },
        },
      },
      {
        v: "-",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 12, bold: true },
        },
      },
      {
        v: "End",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 12, bold: true },
        },
      },
      {
        v: "Start",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 12, bold: true },
        },
      },
      {
        v: "-",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 12, bold: true },
        },
      },
      {
        v: "End",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 12, bold: true },
        },
      },
      ...generateArray(8, ""),
    ],
    ...populateData,
  ];

  ws = utils.aoa_to_sheet(aoaData);
  const colWidth = [
    80, 75, 50, 75, 75, 50, 75, 75, 75, 150, 60, 150, 600, 150, 60,
  ];

  colWidth.forEach((width, columnIndex) => {
    ws["!cols"] = ws["!cols"] || [];
    ws["!cols"][columnIndex] = { hidden: false, wpx: width };
  });

  // Border All
  const startRow = 0; // Baris awal range
  const endRow = aoaData.length; // Baris akhir range
  const startCol = 0; // Kolom awal range
  const endCol = 15; // Kolom akhir range

  for (let row = startRow; row <= endRow; row++) {
    for (let col = startCol; col <= endCol; col++) {
      const cellAddress = utils.encode_cell({ r: row, c: col });
      const cell = ws[cellAddress];
      if (!cell) continue;

      cell.s = {
        ...cell.s,
        border: DEFAULT_BORDER_STYLE,
      };
    }
  }

  if (!ws["!merges"]) ws["!merges"] = [];
  ws["!merges"].push(utils.decode_range("A3:A4"));
  ws["!merges"].push(utils.decode_range("B1:D1"));
  ws["!merges"].push(utils.decode_range("B2:D2"));
  ws["!merges"].push(utils.decode_range("B3:D3"));
  ws["!merges"].push(utils.decode_range("E1:I1"));
  ws["!merges"].push(utils.decode_range("E2:I2"));
  ws["!merges"].push(utils.decode_range("E3:G3"));
  ws["!merges"].push(utils.decode_range("H3:H4"));
  ws["!merges"].push(utils.decode_range("I3:I4"));
  ws["!merges"].push(utils.decode_range("J1:J2"));
  ws["!merges"].push(utils.decode_range("J3:J4"));
  ws["!merges"].push(utils.decode_range("K1:O2"));
  ws["!merges"].push(utils.decode_range("K3:O4"));

  if (!ws["!rows"]) ws["!rows"] = [];
  const fontHeight = 12;
  const padding = 4;

  for (const [index] of data.entries()) {
    ws["!merges"].push(utils.decode_range(`K${index + 5}: O${index + 5}`));
    const { s } = utils.decode_range(`K${index + 5}: O${index + 5}`);
    const cellAddress = s;
    const cell = ws[utils.encode_cell(cellAddress)];
    const wrapText = cell.v.toString().split("\n");
    const lines = wrapText.length;
    const rowHeight = Math.round((fontHeight + padding) * lines);
    ws["!rows"][s.r] = { hpt: rowHeight, hpx: rowHeight };
  }

  utils.book_append_sheet(wb, ws, userData?.user?.slice(0, 30));
};

export const exportSingleWs = (userData, type, filename) => {
  const wb = utils.book_new();
  if (type === "Allo/BI")
    exportAlloBITemlplate(wb, userData.data, {
      name: userData?.user_name,
      team_lead: userData?.team_lead,
      project_manager: userData?.project_manager,
    });
  if (type === "DDB/EDM")
    exportDDBEDMTemlplate(wb, userData.data, {
      name: userData?.user_name,
      team_lead: userData?.team_lead,
      project_manager: userData?.project_manager,
    });
  if (type === "Ragunan")
    exportRagunanTemlplate(wb, userData.data, {
      name: userData?.user_name,
      team_lead: userData?.team_lead,
      project_manager: userData?.project_manager,
    });
  type && writeFile(wb, filename ? `${filename}.xlsx` : "export.xlsx");
};

export const exportMultipleWs = (allData, type, filename) => {
  const wb = utils.book_new();
  for (let logbook of allData) {
    if (type === "Allo/BI") exportAlloBITemlplate(wb, logbook.data);
    if (type === "DDB/EDM")
      exportDDBEDMTemlplate(wb, logbook.data, {
        name: logbook?.user_name,
        team_lead: logbook?.team_lead,
        project_manager: logbook?.project_manager,
      });
    if (type === "Ragunan")
      exportRagunanTemlplate(wb, logbook.data, {
        team_lead: logbook?.team_lead,
        name: logbook?.user_name,
        project_manager: logbook?.project_manager,
      });
  }
  type && writeFile(wb, filename ? `${filename}.xlsx` : "export.xlsx");
};

export const thirtyDaysAgo = (date) => {
  // Mendapatkan tanggal hari ini
  let hariIni = date ? new Date(date) : new Date();

  // Mengurangi 30 hari dari tanggal hari ini
  hariIni.setDate(hariIni.getDate() - 30);

  // Mendapatkan tanggal, bulan, dan tahun setelah dikurangi 30 hari
  let tanggalDikurangi30Hari = hariIni.getDate();
  let bulanDikurangi30Hari = hariIni.getMonth() + 1; // Ingat bulan berbasis nol
  let tahunDikurangi30Hari = hariIni.getFullYear();

  // Format tanggal
  let tanggalFormat =
    tahunDikurangi30Hari +
    "-" +
    bulanDikurangi30Hari +
    "-" +
    tanggalDikurangi30Hari;

  return tanggalFormat;
};

export const mapLogbookWithHoliday = (data, filter, holidaysMap) => {
  const diff =
    filter?.startDate && filter?.endDate
      ? filter?.endDate - filter?.startDate
      : 0;

  const diffCount = Math.floor(diff / (1000 * 60 * 60 * 24)) || 30;
  const dataMap = new Map(data.map((d) => [d.date, d]));

  const dataWithHolidays = [];
  let currentDate =
    filter?.startDate && filter?.endDate
      ? new Date(filter?.startDate)
      : new Date(thirtyDaysAgo());

  for (let i = 0; i <= diffCount; i++) {
    if (i !== 0) currentDate.setDate(currentDate.getDate() + 1);

    const formattedDate = currentDate.toLocaleDateString("id");
    const dataValue = dataMap.get(formattedDate) ||
      holidaysMap.get(formattedDate) ||
      (currentDate.getDay() === 6 || currentDate.getDay() === 0
        ? {
            date: formattedDate,
            value: currentDate.getDay() === 6 ? "Sabtu" : "Minggu",
          }
        : undefined) || { date: formattedDate, value: "" };
    dataWithHolidays.push(dataValue);
  }

  return dataWithHolidays;
};

export const getStyleAndText = (key) => {
  if (key && key.includes("process")) return MAP_CHIP_DATA.get("process");
  if (key && MAP_CHIP_DATA.get(key) !== undefined)
    return MAP_CHIP_DATA.get(key);
  return { styles: { color: "secondary" }, text: "" };
};

export const fuzzySearch = (
  data,
  defaultData,
  options,
  setterFn,
  searchKey
) => {
  const fuse = new Fuse(data, options);
  const result = fuse.search(searchKey);

  if (searchKey === "") return setterFn(defaultData);
  setterFn(result.map((r) => r.item));
};

export const formatPresenceExcel = (rawData) =>
  rawData
    .sort((a, b) => a.created_at - b.created_at)
    .map(
      ({
        created_at,
        user,
        location,
        clock_in,
        clock_out,
        work_mode,
        fid,
      }) => ({
        fid,
        date: convertTimeFromDate.toLocaleDateString(created_at, "en"),
        user: user?.name || "-",
        team: user?.team || "-",
        location: `${location.lat}, ${location.lng}`,
        clock_in: clock_in
          ? convertTimeFromDate.toLocaleTimeString(clock_in)
          : "-",
        clock_out: clock_out
          ? convertTimeFromDate.toLocaleTimeString(clock_out)
          : "-",
        work_mode,
        created_at: convertTimeFromDate.toLocaleDateString(created_at, "en"),
      })
    );

export const formatLogbookExcel = (rawData) => {
  const formattedData = rawData
    .sort((a, b) => a.selected_date - b.selected_date)
    .map((data) => {
      const date = convertTimeFromDate.toLocaleDateString(
        data.selected_date,
        "en"
      );
      const [month, day, year] = date.split("/");
      return {
        id: data.fid,
        date,
        user: data?.user?.name || "-",
        team: data?.current_team || data?.user?.team?.name || "-",
        project: data?.project || "-",
        usecase: data?.detail?.map((act) => act.usecase.name || "-"),
        activity: data?.detail?.map((act) =>
          act?.activity?.map((ac) =>
            !ac?.value
              ? "-"
              : `${ac.value} // ${ac.progress.value} // ${ac?.progress?.type} // ${ac?.progress?.percentage} %`
          )
        ),
        next_activity: data?.detail?.map((act) =>
          act?.next_activity?.map((ac) => ac || "-")
        ),
        work_mode: data.work_mode,
        clock_in: data.clock_in
          ? convertTimeFromDate.toLocaleTimeString(data.clock_in)
          : "-",
        clock_out: data.clock_out
          ? convertTimeFromDate.toLocaleTimeString(data.clock_out)
          : "-",
        month,
        day,
        year,
        created_at: convertTimeFromDate.toLocaleDateString(
          data.created_at,
          "en"
        ),
      };
    });

  const finalData = [];

  for (const data of formattedData) {
    for (let i = 0; i < data.usecase.length; i++) {
      for (
        let j = 0;
        j < data.activity[i].length || j < data.next_activity[i].length;
        j++
      ) {
        finalData.push({
          ...data,
          usecase: data.usecase[i],
          activity: data.activity[i][j] || "-",
          next_activity: data.next_activity[i][j] || "-",
        });
      }
    }
  }
  return finalData;
};

export const mappingSummaryData = (
  logbookData,
  holidaysMap,
  presenceData = []
) => {
  const { periodString: period } = getCurrentPeriod();
  const summaryData = [];

  const presenceMapData = new Map(
    presenceData.map((pres) => [
      convertTimeFromDate.toDateString(pres.created_at) + pres.user_id,
      pres,
    ])
  );

  for (const logbook of logbookData) {
    const existingUser = summaryData.findIndex(
      (sd) => sd.userId === logbook.user_id
    );

    const presence = presenceMapData.get(
      convertTimeFromDate.toDateString(logbook.selected_date) + logbook.user_id
    );

    const formattedLogbook = {
      ...logbook,
      work_mode: presence?.work_mode || logbook?.work_mode,
      clock_in: presence?.clock_in || "",
      clock_out: presence?.clock_out || "",
    };

    if (existingUser === -1) {
      summaryData.push({
        userId: formattedLogbook.user_id,
        name: formattedLogbook?.user?.name || "",
        period,
        lastUpdated: formattedLogbook.updated_at,
        lastCreated: formattedLogbook.created_at,
        totalData: 1,
        logbookData: [formattedLogbook],
      });
      continue;
    }

    const userData = summaryData[existingUser];
    if (userData.lastUpdated < formattedLogbook.updated_at)
      userData.lastUpdated = formattedLogbook.updated_at;
    if (userData.lastCreated < formattedLogbook.created_at)
      userData.lastCreated = formattedLogbook.created_at;
    userData.totalData += 1;
    userData.logbookData.push(formattedLogbook);
  }

  for (const indexSum in summaryData) {
    const formatSummaryData = generatePeriodeThisMonth(
      summaryData[indexSum].logbookData,
      holidaysMap
    );
    summaryData[indexSum].logbookData = formatSummaryData;
    summaryData[indexSum].isCompleted =
      formatSummaryData.missingData.length === 0 ? "Completed" : "Uncompleted";
  }

  return summaryData;
};

export const generatePeriodeThisMonth = (data, holidaysMap) => {
  const endDate = "10";
  const startDate = "11";
  const date = new Date();

  const endPeriode = new Date(
    date.getFullYear() + "-" + (Number(date.getMonth()) + 1) + "-" + endDate
  );
  const startPeriode = new Date(
    date.getFullYear() + "-" + date.getMonth() + "-" + startDate
  );

  const daysDiff = (endPeriode - startPeriode) / (1000 * 3600 * 24);

  let currentDate = startPeriode;

  const dataMap = new Map(
    data.map((d) => [
      convertTimeFromDate.toLocaleDateString(d.selected_date),
      { ...d, date: convertTimeFromDate.toLocaleDateString(d.selected_date) },
    ])
  );

  const allData = [];
  const dataFilled = [];
  const missingData = [];

  for (let i = 0; i <= daysDiff; i++) {
    if (i !== 0) currentDate.setDate(currentDate.getDate() + 1);
    const formattedDate = currentDate.toLocaleDateString("id");
    const dataValue = dataMap.get(formattedDate) ||
      holidaysMap.get(formattedDate) ||
      (currentDate.getDay() === 6 || currentDate.getDay() === 0
        ? {
            date: formattedDate,
            value: currentDate.getDay() === 6 ? "Sabtu" : "Minggu",
          }
        : undefined) || { date: formattedDate, value: "" };
    if (dataValue.value === "") missingData.push(dataValue);
    if (dataValue.selected_date) dataFilled.push(dataValue);
    allData.push(dataValue);
  }

  return { dataFilled, missingData, allData };
};

export const convertToDateObj = (strDate) => {
  const [day, month, year] = strDate.split("/");

  // Month needs to be zero-based, so subtract 1 from the month value
  const dateObject = new Date(year, month - 1, day);

  return dateObject;
};

export const objectToQueryParam = (object) => {
  const params = new URLSearchParams();

  for (const key in object) {
    if (object.hasOwnProperty(key) && object[key]) {
      params.append(key, object[key]);
    }
  }

  return params.toString();
};

export const getPresenceTodayParams = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return {
    start: `${year}-${month}-${day}`,
    end: `${year}-${month}-${day}`,
  };
};

const options = {
  day: "numeric",
  month: "long",
  year: "numeric",
  timeZone: "Asia/Jakarta",
};

export const getCurrentPeriod = () => {
  const date = new Date();
  let endPeriodeDate = new Date(date);

  if (date.getDate() >= 11) {
    endPeriodeDate.setMonth(date.getMonth() + 1);
  }
  endPeriodeDate.setDate(11);
  let startPeriodeDate = new Date(endPeriodeDate);

  startPeriodeDate.setMonth(endPeriodeDate.getMonth() - 1);
  startPeriodeDate.setDate(11);

  const end = endPeriodeDate.toISOString().split("T")[0];
  const start = startPeriodeDate.toISOString().split("T")[0];

  const startDateString = startPeriodeDate.toLocaleDateString("id", options);
  endPeriodeDate.setDate(10);
  const endDateString = endPeriodeDate.toLocaleDateString("id", options);

  const periodString = `T${
    getMonthDiff(start) + initTPeriod.period
  } (${startDateString} - ${endDateString})`;

  return { start, end, periodString };
};

export function generatePeriods() {
  const periods = [];
  let currentDate = new Date(initTPeriod.startDate);
  let numPeriods = getMonthDiff();

  if (new Date().getDate() >= 11) {
    numPeriods += 1;
  }

  for (let i = 0; i < numPeriods; i++) {
    const startDate = new Date(currentDate);
    startDate.setDate(11);
    const endDate = new Date(startDate);
    endDate.setMonth(endDate.getMonth() + 1);

    periods.push({
      periodString: `T${initTPeriod.period + i} (${startDate.toLocaleDateString(
        "id",
        options
      )} - ${endDate.toLocaleDateString("id", options)})`,
      period: initTPeriod.period + i,
      startDate: startDate.toISOString().slice(0, 10),
      endDate: endDate.toISOString().slice(0, 10),
    });

    currentDate = endDate;
  }

  return periods;
}

export function validatePassword(password) {
  // Memastikan panjang password minimal 8 karakter
  if (password.length < 8) {
    toast.error("Password must be at least 8 characters long.");
    return false;
  }

  // Memastikan minimal 1 huruf kecil dan 1 huruf besar
  const lowercaseRegex = /[a-z]/;
  const uppercaseRegex = /[A-Z]/;

  if (!lowercaseRegex.test(password) || !uppercaseRegex.test(password)) {
    toast.error(
      "Password must contain at least 1 lowercase letter and 1 uppercase letter."
    );
    return false;
  }

  // Jika semua aturan terpenuhi, password valid
  return true;
}
