import { create } from "zustand";
// import { createJSONStorage, persist } from "zustand/middleware";
// import { get, set, del } from "idb-keyval";

const initUiState = {
  globalLoading: false,
  validWFO: false,
  openDialog: false,
  userLoggedIn: null,
  isFromPresence: false,
  fromProfileTable: {
    status: false,
    state: { date: new Date() },
  },
  currentProfileTab: 0,
  currentAdminTab: 0,
  clockOutData: {},
  presenceInfo: [],
  logbookInfo: [],
  allPresenceData: [],
  allLogbookData: [],
  allProgressData: [],
  allTeamData: [],
  allRoleData: [],
  allUserData: [],
  allWorkplaceData: [],
  selectedLogbook: {},
  projects: [],
  allProgress: [],
  allTools: [],
  allUserRequest: [],
  allRequest: [],
  filteredUserRequest: [],
  filteredAllRequest: [],
  selectedPresenceWorkplace: {},
  currentReportTab: 0,
  currentRequestTab: 0,
  logbookSummaryData: [],
  allHolidayData: [],
  mapPresenceData: new Map(),
  holidaysMap: new Map(),
  selectedRequest: {},
  exportData: [],
  overtimeData: [],
  selectedDocTab: {
    index: 0,
    name: "ISO9001",
  },
};

// Custom storage object
// const storage = {
//   getItem: (name) => {
//     // console.log(name, "has been retrieved");
//     return atob(JSON.parse(sessionStorage.getItem(name)));
//   },
//   setItem: (name, value) => {
//     // console.log(name, "with value", value, "has been saved");
//     sessionStorage.setItem(name, btoa(JSON.stringify(value)));
//   },
//   removeItem: (name) => {
//     // console.log(name, "has been deleted");
//     sessionStorage.removeItem(name);
//   },
// };

export const useUiStateStore = create(
  // persist(
  (set, get) => ({
    ...initUiState,
    setSelectedDocTab: (payload) => set({ selectedDocTab: payload }),
    setHolidaysMap: (payload) => set({ holidaysMap: payload }),
    setExportData: (payload) => set({ exportData: payload }),
    setSelectedRequest: (payload) => set({ selectedRequest: payload }),
    setMapPresenceData: (payload) => set({ mapPresenceData: payload }),
    setAllHolidayData: (payload) => set({ allHolidayData: payload }),
    setLogbookSummaryData: (payload) => set({ logbookSummaryData: payload }),
    setOvertimeData: (payload) => set({ overtimeData: payload }),
    setFilteredUserRequest: (payload) => set({ filteredUserRequest: payload }),
    setFilteredAllRequest: (payload) => set({ filteredAllRequest: payload }),
    setCurrentReportTab: (payload) => set({ currentReportTab: payload }),
    setCurrentRequestTab: (payload) => set({ currentRequestTab: payload }),
    setAllRequest: (payload) => set({ allRequest: payload }),
    setAllTools: (payload) => set({ allTools: payload }),
    setFromProfileTable: (payload) => set({ fromProfileTable: payload }),
    setSelectedPresenceWorkplace: (payload) =>
      set({ selectedPresenceWorkplace: payload }),
    setProjects: (payload) => set({ projects: payload }),
    setAllProgress: (payload) => set({ allProgress: payload }),
    setIsFromPresence: (payload) => set({ isFromPresence: payload }),
    setGlobalLoading: (payload) => set({ globalLoading: payload }),
    setSelectedLogbook: (payload) => set({ selectedLogbook: payload }),
    setOpenDialog: (payload) => set({ openDialog: payload }),
    setValidWFO: (payload) => set({ validWFO: payload }),
    setPresenceInfo: (payload) => set({ presenceInfo: payload }),
    setLogbookInfo: (payload) => set({ logbookInfo: payload }),
    setAllLogbookData: (payload) => set({ allLogbookData: payload }),
    setAllTeamData: (payload) => set({ allTeamData: payload }),
    setAllUserData: (payload) => set({ allUserData: payload }),
    setAllRoleData: (payload) => set({ allRoleData: payload }),
    setAllWorkplaceData: (payload) => set({ allWorkplaceData: payload }),
    setAllPresenceData: (payload) => set({ allPresenceData: payload }),
    setClockOutData: (payload) => set({ clockOutData: payload }),
    setCurrentProfileTab: (payload) =>
      set({
        currentProfileTab: payload,
      }),
    setCurrentAdminTab: (payload) =>
      set({
        currentAdminTab: payload,
      }),
    setUserLoggedIn: (payload) => set({ userLoggedIn: payload }),
    setAllUserRequest: (payload) => set({ allUserRequest: payload }),
    resetAllStateAndSignOut: (callback) => {
      sessionStorage.clear();
      set({ ...initUiState });
      callback && callback();
    },
  })
  //   {
  //     name: "app-state",
  //     storage: createJSONStorage(() => storage),
  //   }
  // )
);
