import { Chip } from "@mui/material";
import SoftTypography from "components/UI/SoftTypography";
import { convertToHoursMinutes } from "utils";

export const columns = [
  {
    accessorKey: "user.name",
    cell: (info) => (
      <SoftTypography fontSize={13}>{info.getValue()}</SoftTypography>
    ),
    header: "Name",
  },
  {
    accessorKey: "user.role",
    cell: (info) => (
      <SoftTypography fontSize={13}>{info.getValue()}</SoftTypography>
    ),
    header: "Role",
  },
  {
    accessorKey: "user.team",
    cell: (info) => (
      <SoftTypography fontSize={13}>{info.getValue()}</SoftTypography>
    ),
    header: "Project",
  },
  {
    accessorKey: "user.project_manager",
    cell: (info) => (
      <SoftTypography fontSize={13}>{info.getValue() || "-"}</SoftTypography>
    ),
    header: "Project Manager",
  },
  {
    accessorKey: "workdays_count",
    cell: (info) => (
      <SoftTypography fontSize={13}>{info.getValue()}</SoftTypography>
    ),
    header: "Work Days",
  },
  {
    cell: ({ row }) => {
      const dataDays =
        row.original.workdays_count -
        (row.original.workdays_count - Object.keys(row.original.data).length);
      return (
        <SoftTypography fontSize={13}>
          {dataDays < 0 ? 0 : dataDays}
        </SoftTypography>
      );
    },
    header: "Present Days",
  },
  {
    cell: ({ row }) => {
      const dataDays =
        row.original.workdays_count - Object.keys(row.original.data).length;
      return (
        <SoftTypography fontSize={13}>
          {dataDays < 0 ? 0 : dataDays}
        </SoftTypography>
      );
    },
    header: "Absent Days",
  },
  {
    accessorKey: "annual_leave",
    cell: (info) => (
      <SoftTypography fontSize={13}>{info.getValue()}</SoftTypography>
    ),
    header: "Annual Leave",
  },
  {
    accessorKey: "sick_leave",
    cell: (info) => (
      <SoftTypography fontSize={13}>{info.getValue()}</SoftTypography>
    ),
    header: "Sick Leave",
  },
  {
    accessorKey: "overtime_count",
    cell: (info) => (
      <SoftTypography fontSize={13}>{info.getValue()}</SoftTypography>
    ),
    header: "Overtime Days",
  },

  {
    accessorKey: "requested_overtime_total_seconds",
    cell: (info) => {
      const overtimeInHours = new Date(info.getValue()).getHours();
      return (
        <Chip
          component="span"
          size="small"
          label={convertToHoursMinutes(info.getValue())}
          variant="contained"
          sx={{ color: "#fff", fontSize: 11, py: 1.5, px: 0.5 }}
          color={
            overtimeInHours > 20
              ? "error"
              : overtimeInHours > 10 && overtimeInHours <= 20
              ? "warning"
              : "success"
          }
        />
      );
    },
    header: "Overtime Requested",
  },
  {
    accessorKey: "valid_overtime_total_seconds",
    cell: (info) => {
      const overtimeInHours = new Date(info.getValue()).getHours();
      return (
        <Chip
          component="span"
          size="small"
          label={convertToHoursMinutes(info.getValue())}
          variant="contained"
          sx={{ color: "#fff", fontSize: 11, py: 1.5, px: 0.5 }}
          color={
            overtimeInHours > 20
              ? "error"
              : overtimeInHours > 10 && overtimeInHours <= 20
              ? "warning"
              : "success"
          }
        />
      );
    },
    header: "Overtime Approved",
  },
  // {
  //   cell: ({ row }) => {
  //     const absentInSeconds =
  //       (row.original.workdays_count -
  //         Object.keys(row.original.data).length +
  //         row.original.sick_leave) *
  //       2 *
  //       3600;

  //     const apporvedRulesInSeconds =
  //       row.original.valid_overtime_total_seconds - absentInSeconds;
  //     return (
  //       <SoftTypography fontSize={13}>
  //         {convertToHoursMinutes(apporvedRulesInSeconds)}
  //       </SoftTypography>
  //     );
  //   },
  //   header: "Overtime Rules",
  // },
];
